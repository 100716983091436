import React from 'react';
import { useSelector } from 'react-redux';
import BondTable from '../bonds/BondTable';
import EmployTable from '../employment/EmploymentTable';
import IncomeTable from '../income/IncomeTable';
import PayementTable from '../payment/PaymentTable';
import MemberTable from '../member/memberTable';
import { convertSnakeToCamel } from '../common/common';

const SearchList = (props) => {

    const bondData = useSelector(state => state.searches.bondSearches.data);
    const employData = useSelector(state => state.searches.employSearches.data);
    const incomeData = useSelector(state => state.searches.incomeSearches.data);
    const paymentData = useSelector(state => state.searches.paymentSearches.data);
    const memberData = useSelector(state => state.searches.memberSearches.data);

    if(props.page === 'employment') {
        return(
            <>
                <EmployTable data={employData} />
            </>
        )
    } else if (props.page === 'bond'){
        return(
            <>
                <BondTable data={bondData} />
            </>
        )
    } else if (props.page === 'income'){
        return(
            <>
                <IncomeTable data={incomeData} />
            </>
        )
    } else if (props.page === 'payment'){
        return(
            <>
                <PayementTable data={paymentData} />
            </>
        )
    } else if (props.page === 'member'){
        return(
            <>
                <MemberTable data={convertSnakeToCamel(memberData)} />
            </>
        )
    }

    return(
        <>
        </>
    )
}

export default SearchList;