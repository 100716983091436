import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../parts/Header';
import Sidebar from '../parts/Sidebar';
import SideHeader from '../../components/parts/SideHeader';
import { FooterBoard } from '../parts/Footer';
import MemberList from '../../_components/member/memberList';
import Paginations from '../parts/Paginations';
import SearchList from '../../_components/search/searchList';
import MemberSearch from '../../_components/search/searchParts/memberSearch';
import { getMembers } from '../../_actions/member_action';
import { getMemberSearch } from '../../_actions/search_action';


const MemberPage = (props) => {

    const MEMBER = 'member';
    const { mode } = useSelector(state => state.modes);
    const { count } = useSelector(state => state.member.members);
    const { members } = useSelector(state => state.member);
    const memberCurrentPage = useSelector(state => state.member.members.currentPage);
    const memberPageChunk = useSelector(state => state.member.members.pageChunk);

    const searchCount = useSelector(state => state.searches.memberSearches.count);
    const searchQuery = useSelector(state => state.searches.memberSearches.query);
    const searchCurrentPage = useSelector(state => state.searches.memberSearches.currentPage);
    const searchPageChunk = useSelector(state => state.searches.memberSearches.pageChunk);

    let contents;

    //URLSearchParams로 react-router의 query String을 object화
    let search = new URLSearchParams(props.location.search);

    //get 메소드로 해당 key 값 조회 (?page=1 일 시 page가 key가 되고 1이 value)
    let pageNum = parseInt(search.get('page')) || 1;


    if (mode === 'read') {
        contents = <div className="main-content">
            <SideHeader big="MEMBER" small="고객 목록" />
            <MemberSearch />
            <MemberList data={members} />
            <Paginations 
                page={MEMBER} 
                count={count} 
                pageNum={pageNum}
                currentPage={memberCurrentPage} 
                pageChunk={memberPageChunk}
                dispatch={getMembers}
            /> 
        </div>
    } else if (mode === 'search') {
        contents = <div className="main-content">
            <SideHeader big="MEMBER" small="고객 검색 결과"/>
            <MemberSearch />
            <SearchList page={MEMBER}/>
            <Paginations 
                page={MEMBER} 
                pageNum={pageNum} 
                count={searchCount} 
                query={searchQuery}
                currentPage={searchCurrentPage} 
                pageChunk={searchPageChunk}
                dispatch={getMemberSearch}
            />
        </div>
    };

    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            {contents}
            <FooterBoard />
        </div>
    );
    
};

export default withRouter(MemberPage);
