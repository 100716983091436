import { Button } from 'reactstrap';
import { ExcelFile, ExcelSheet, ExcelColumn } from '../../../components/parts/excelExports';


//청구서 존재 여부 출력 함수
export const paymentUuidExistCheck = (schedueldPaymentUuid) => {
    if(schedueldPaymentUuid !== null) {
        return 'Y'
    }
    return 'N';
}

//table header component
export const TableThread = () => {
    return(
        <thead>
            <tr align="center">
                <td></td>
                <td>이름</td> 
                <td>연락처</td>
                <td>과정명</td> 
                <td>취업처</td>
                <td>급여지급일</td>
                <td>명세서제출일</td>
                <td>월급여</td>
                <td>급여명세서</td>
                <td>청구서발행여부</td>
                <td>청구서정보</td>
            </tr> 
        </thead>
    )
};

//excel export component
export const TableExport = ({data}) => {
    return (
        <ExcelFile element={<Button color="success" style={{marginBottom:'30px'}}>Export Excel</Button>}>
            <ExcelSheet data={data} name="급여 리스트">
                <ExcelColumn label="이름" value="name"/>
                <ExcelColumn label="연락처" value="phone"/>
                <ExcelColumn label="과정명" value="course" />
                <ExcelColumn label="취업처" value="companyName" />
                <ExcelColumn label="급여지급일" value="incomeDate" />
                <ExcelColumn label="명세서제출일" value="incomeDocDate" />
                <ExcelColumn label="월급여" value="monthlyIncome" />
            </ExcelSheet>
        </ExcelFile>
    )
}