import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import './assets/css/header.css';
import './assets/css/footer.css';
import './assets/css/sidebar.css';
import './assets/css/pagination.css';
import './assets/css/parts.css';
import './assets/css/form.css';
import './assets/css/table.css';
import './assets/css/login.css';
import './assets/css/loading.css';
import './assets/css/search.css';
import './assets/css/radio.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import promiseMiddleware from 'redux-promise';
import ReduxThunk from 'redux-thunk';
import rootReducer from './_reducers';
import logger from 'redux-logger';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";

let store;

if(process.env.REACT_APP_HDM_API_ENV === "production"){
    //run build 시 적용되는 환경 : production (상용계 페이지에서는 데이터의 흐름 안 보임)
    store = createStore(
        rootReducer,
        applyMiddleware(
            ReduxThunk, 
            promiseMiddleware, 
        )
    )
}else {
    //run start, run build:dev 시 적용되는 환경 : development (개발계 페이지, 로컬 환경에서는 데이터의 흐름 보임)
    store = createStore(
        rootReducer,
        composeWithDevTools(
            applyMiddleware(
                ReduxThunk, 
                promiseMiddleware, 
                logger
            )
        )
    )
};

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
reportWebVitals();
