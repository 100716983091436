import { ExcelFile, ExcelSheet, ExcelColumn } from '../../../components/parts/excelExports';
import { Button } from 'reactstrap';


//채권 타입 출력 함수
export const bondType = (bondType) => {
    if(bondType === 'ISA'){
        return 'ISA';
    } else if (bondType === 'GENERAL') {
        return '일반(후불제)';
    } else if (bondType === 'PREPAID') {
        return '일반(선불제)';
    }
};

//채권 상태 출력 함수
export const bondStatus = (bondStatus) => {
    if(bondStatus){
        let bond_status = bondStatus.bond_status;
        switch (bond_status) {
            case 1:
                return '납부 전';
            case 2:
                return '납부 진행';
            case 3:
                return '납부 완료';
            case 4:
                return <span style={{color:'red'}}>납부 연체</span>;
            case 5:
                return '납부 완료(연체)';
            case 6:
                return <span style={{color:'red'}}>납부 연체(추심)</span>;
            case 7:
                return '강제 종료';
            case 8:
                return '무효';
            case 9:
                return '포기';
            case 10 :
                return '알 수 없음';
            case 11 :
                return '미납';
            default :
                return '알 수 없음';
        }
    }
    return '알 수 없음';
};

//ISA 소득 공유 비율 출력 함수
export const bondPercentage = (bondPolicy) => {
    let { bond_type } = bondPolicy;
    if(bond_type === 'ISA') {
        return `${Math.floor(bondPolicy.isa_policy.income_share_percentage * 100)}%`;
    } else {
        return '';
    }
};

//일반 후불제/ISA/선불제 납부 횟수 출력 함수
export const getBondPaymentTimes = (bondData) => {
    let { bond_type } = bondData?.bond_policy;
    if (bond_type === 'ISA') {
        //전체 납부 횟수 출력
        let { total_payment_times } = bondData?.bond_policy?.isa_policy;
        //숫자 0은 출력 안 함
        return (total_payment_times > 0) ? total_payment_times : '';
    } else if (bond_type === 'PREPAID') {
        //선불제는 1번만 낸다.
        return '1';
    } else {
        //만들어진 청구서 갯수 출력
        let { total_scp_times } = bondData?.payment_info;
        //숫자 0은 출력 안 함
        return (total_scp_times > 0) ? total_scp_times : '';
    };
};

//보증금 타입 출력 함수
export const bondDeposit = (bondPolicy) => {
    let { deposit_policy } = bondPolicy;
    if(deposit_policy){
        let {type} = deposit_policy;
        if(type === 'INCLUDE_1ST_PAYMENT'){
            return '보증금(ISA)'
        } else if (type === 'GENERAL') {
            return '보증금(일반)'
        }
    }
    return '';
};

//table header component
export const TableThread = () => {
    return(
        <thead>
            <tr align="center">
                <td></td>
                <td>등록일</td>
                <td>이름</td> 
                <td>연락처</td>
                <td>과정명</td>
                <td>채권유형</td>
                <td>채권시작일</td>
                <td>채권종료일</td>
                <td>%</td>
                <td>횟수</td>
                <td>보증금유형</td>
                <td>채권상태</td>
                <td>근로계약서</td>
            </tr> 
        </thead>
    )
};

//엑셀 export 함수. excel export 라이브러리 사용.
const TableExport = ({data}) => {
    
    return (
        <ExcelFile element={<Button color="success" style={{marginBottom: "30px"}}>Export Excel</Button>}>
            <ExcelSheet data={data} name="채권 리스트">
                <ExcelColumn label="등록일" value="created-time"/>
                <ExcelColumn label="이름" value="name"/>
                <ExcelColumn label="연락처" value="phone"/>
                <ExcelColumn label="과정명" value="course" />
                <ExcelColumn label="채권유형" value="bondType" />
                <ExcelColumn label="채권시작일" value="bondStartDate" />
                <ExcelColumn label="채권종료일" value="bondEndDate" />
                <ExcelColumn label="%" value="%" />
                <ExcelColumn label="횟수" value="count" />
                <ExcelColumn label="보증금유형" value="depositType" />
                <ExcelColumn label="채권상태" value="bondStatus" />
            </ExcelSheet>
        </ExcelFile>
    );
};

export default TableExport;