import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { createPageArr, pageLimit } from '../../_components/common/common';

 const Pagination = (props) => {

    //페이지를 덩어리로 묶어 넘버링을 해주는 state. 0부터 시작한다. (ex. 0: 1~10, 1: 11~20)
    const [pageChunk, setPageChunk] = useState(props.pageChunk, 0);
    //현재 페이지를 담을 state
    const [currentNum, setCurrentNum] = useState(props.pageNum, 1);
    //페이지 간격 pageLimit의 수에 따라 간격이 달라진다. (ex. pageLimit=5, 1~5 / pageLimit=20, 1~20);
    const pageRange =  props.pageChunk * pageLimit;
    //페이지네이션을 보여줄 컴포넌트 변수
    let pagination;
    //총 페이지 수
    let totalPage = Math.ceil(props.count/pageLimit) || "";
    //페이지의 수가 원소가 되어 담긴 페이지 배열
    let pageNumArr = createPageArr(totalPage);
    //페이지의 간격대로 페이지의 배열을 슬라이스해 화면에 숫자를 뿌려주는 배열
    let pageSliceArr = pageNumArr.slice(pageRange, Number(pageLimit) + pageRange);
    //offset
    let currents = (currentNum - 1) * pageLimit|| 0;

    const dispatch = useDispatch();

    const currentPageChunk = (page) => {
        let reSettingBlockNum;

        //페이지 블록의 경우 0: 1~10, 1: 11~20 으로 묶여 있기 때문에 이행해줘야 하는 분기 처리
        if(page <= pageLimit) {
            reSettingBlockNum = `0${String(page- 1)}`;
        } else if (page > pageLimit) {
            reSettingBlockNum = String(page - 1);
        };

        if(reSettingBlockNum){
            return parseInt(reSettingBlockNum[0]);
        };
    };

    //이전 페이지 간격으로 돌아가도록 해주는 함수. < 를 누르면 pageRange 간격만큼 이동한다.
    const movePreviousPage = () => {
        //부모 컴포넌트로부터 받아온 state 값인 pageNum가 1보다 작을 경우 아무것도 행하지 않는다.
        if(props.pageNum < 1) {
            return;
        };

        if((props.currentPage - 1) <= pageLimit * props.pageChunk){
            //페이지 블록 넘버링
            setPageChunk(n => n - 1);
        };

        //현재 페이지 재설정
        setCurrentNum(n => n - 1);
    };

    //다음 페이지 간격을 보여주는 함수. > 를 누르면 pageRange 간격만큼 이동한다.
    const moveNextPage = () => {
        //부모 컴포넌트로부터 받아온 state 값인 pageNum가 총 페이지 수보다 클 시 아무것도 행하지 않는다.
        if(props.pageNum >= totalPage) {
            return;
        };

        if(pageLimit * Number(pageChunk + 1) < Number(currentNum + 1)) {
            //페이지 블록 넘버링
            setPageChunk(n => n + 1);
        };       

        //현재 페이지 재설정
        setCurrentNum(n => n + 1);
    };

    //제일 처음 페이지 (1) 로 돌아가는 함수
    const moveFirstPage = () => {
        setPageChunk(0);
        setCurrentNum(1);
    };

    //제일 마지막 페이지 (페이지 수마다 다름) 로 돌아가는 함수
    const moveLastPage = () => {
        setPageChunk(Math.ceil(totalPage/pageLimit) - 1);
        setCurrentNum(totalPage);
    };

    // //개별 페이지 클릭시 페이지 수를 현재 페이지로 지정해주는 함수
    const handlePageClick = (e) => {
        e.preventDefault();
        let targetNumber = parseInt(e.target.innerHTML);
        setPageChunk(currentPageChunk(targetNumber));
        setCurrentNum(targetNumber);
    };

    //뒤로 가기 버튼을 감지하고 state 값을 맞춰준다.
    window.onpopstate = function (event) {

        //뒤로 가기를 여러 번 눌러 페이지가 1이 될 시 윈도우 새로고침
        if(props.currentPage === 1) {
            window.location.reload();
        };

        //뒤로 갈 시 현재 페이지와 페이지 블록을 이전 페이지와 이전 블록으로 재설정
        setCurrentNum(props.pageNum);
        setPageChunk(currentPageChunk(props.pageNum));
    };

    //Api 호출이 일어나는 곳.
    useEffect(() => {
        
        if (props.query) {
            dispatch(props.dispatch(props.query, currents, pageLimit, currentNum, pageChunk));
        } else {
            dispatch(props.dispatch(currents, pageLimit, currentNum, pageChunk, props));
        };
        
        props.history.push(`/${props.page}?page=${currentNum}`);

    }, [currents, currentNum, pageChunk, dispatch]);

    //총 페이지 수가 1보다 작으면 페이지네이션을 보여주지 않는다.
    if (totalPage <= 1) {
        pagination = null;   
    //총 페이지 수가 1보다 클 시 페이지네이션 컴포넌트를 보여주는 곳
    } else if (totalPage > 1) {
        pagination = <nav>
            <ul className="pagination">
                <PreviousView totalPage={totalPage} function={moveFirstPage} pageNum={props.pageNum} symbol={"<<"} />
                <PreviousView totalPage={totalPage} function={movePreviousPage} pageNum={props.pageNum} symbol={"<"} />
                {pageSliceArr.map(page => {
                    return(
                        <li
                            className={page === props.currentPage ? "active-bagic": ""}
                            key={page}>
                                <button onClick={handlePageClick}>{page}</button>
                        </li>
                    )
                })}
                <NextView totalPage={totalPage} function={moveNextPage} pageNum={props.pageNum} symbol={">"} />
                <NextView totalPage={totalPage} function={moveLastPage} pageNum={props.pageNum} symbol={">>"} />
            </ul>
        </nav>
    };
   
    //최종 렌더링
    return (
        <>
            {pagination}    
        </>
    );

};

//이전 페이지 컴포넌트
const PreviousView = (props) => {
    let previousView;
    
    //총 페이지가 pageLimit보다 클 경우 보여줍니다.
    if(props.totalPage >= pageLimit) {
        //부모 컴포넌트로부터 받아온 state 값인 pageNum의 조건에 따라 해당 버튼의 disabled 여부 결정
        previousView = (props.pageNum <= 1) ? 
            <li><button onClick={props.function} disabled> {props.symbol} </button></li> :
            <li><button onClick={props.function}> {props.symbol} </button></li>
    };
    
    //최종 렌더링
    return (
        <>
            {previousView}
        </>
    );
};

//다음 페이지 컴포넌트
const NextView = (props) => {
    let nextView;

    //총 페이지가 pageLimit보다 클 경우 보여줍니다.
    if(props.totalPage >= pageLimit) {
        //부모 컴포넌트로부터 받아온 state 값인 pageNum의 조건에 따라 해당 버튼의 disabled 여부 결정
        nextView = (props.pageNum >= props.totalPage) ? 
            <li><button onClick={props.function} disabled>{props.symbol}</button></li> :
            <li><button onClick={props.function}>{props.symbol}</button></li>
    };
    
    //최종 렌더링
    return (
        <>
            {nextView}
        </>
    );
};

export default withRouter(Pagination);
