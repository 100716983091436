import Axios from 'axios';

Axios.defaults.xsrfHeaderName = 'X-CSRFToken';
Axios.defaults.xsrfCookieName = 'csrftoken';
Axios.defaults.withCredentials = true;

//브라우저 cookie 추출하는 함수
export const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
    
            if (cookie.substring(0, name.length + 1) === name + '=') {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            };
        };
    };
    return cookieValue;
};

//로컬 스토리지 token 추출하는 함수
const getToken = (name) => {
    return localStorage.getItem(name);
};

let csrftoken = getCookie('csrftoken') || '';
let token = getToken('Token') || '';

//호출 되는 api 내 동일한 config 부분을 함수화
export const headerConfig = (isRequireTokenAuth = false) => {
    return {
        accept : "application/json",
        headers : {
            ...( isRequireTokenAuth ? 
            token && { Authorization: `Token ${token}` } :
            csrftoken && { "X-CSRFToken": csrftoken })
        }
    };
}