import * as incomeAPI from '../api/incomeAPI';
import { 
    GET_INCOME_LIST,
    GET_INCOME_LIST_SUCCESS,
    GET_INCOME_LIST_ERROR,
} from './_types';


export const getIncomes = (offset, pageLimit, currentPage, pageChunk, props) => async dispatch => {
    dispatch({ type: GET_INCOME_LIST }); // 요청 시작
    try {
        const data = await incomeAPI.getIncomePart(offset, pageLimit, currentPage, pageChunk); // API 호출

        if (data[0].results.length > 0) {
            dispatch({ type: GET_INCOME_LIST_SUCCESS, data }); // 요청 성공
        } else {
            alert('존재하지 않는 페이지입니다.');
            const data = await incomeAPI.getIncomePart(0, pageLimit, 1, 0); // API 재호출
            dispatch({ type: GET_INCOME_LIST_SUCCESS, data }); // 재요청 성공
            props.history.push(`/${props.page}?page=1`); // 1 페이지로 redirect
        };
        
    } catch (e) {
        dispatch({ type: GET_INCOME_LIST_ERROR, error: e });
    };
};