import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, Button } from 'reactstrap';
import { compareLatest, autoHyphenPhone, autoHyphenDate, userCourse } from '../common/common';
import EmptyPage from '../common/EmptyPage';
import TableExport , { bondStatus, bondType, bondPercentage, getBondPaymentTimes, bondDeposit, TableThread } from './bondParts/bondFunc';
import UploadBondURL from './bondParts/uploadBondURL';
import ModifyBondStatus from './bondParts/ModifyBondStatus';
import { getBonds } from '../../_actions/bond_action';

//채권 조회 테이블. 채권 상태 수정 기능까지 포함하고 있다.
const BondTable = (props) => {
    
    const [bondData, setBondData] = useState([]);
    let searchCount = useSelector(state => state.searches.bondSearches.count);
    const [tableMode, setTableMode] = useState(true);

    //채권 상세 조회 페이지로 넘어가는 함수
    function handlePageMove(uuid, id, bondType, bondStatus) {
        props.history.push(`/bond/detail/${uuid}`, {
            _uuid: uuid,
            _id: id,
            _bondType: bondType,
            _bondStatus: bondStatus
        });
    };

    useEffect(() => {
        let tr = document.getElementsByTagName("tr");
        let tdArr = [];
        for(let i = 1; i < tr.length; i++) {
            tdArr.push({
                "created-time" : tr[i].cells[1].innerHTML,
                "name" : tr[i].cells[2].innerHTML,
                "phone" : tr[i].cells[3].innerHTML,
                "course" : tr[i].cells[4].innerHTML,
                "bondType" : tr[i].cells[5].innerHTML,
                "bondStartDate" : tr[i].cells[6].innerHTML,
                "bondEndDate" : tr[i].cells[7].innerHTML,
                "%" : tr[i].cells[8].innerHTML,
                "count" : tr[i].cells[9].innerHTML,
                "depositType" : tr[i].cells[10].innerHTML,
                "bondStatus" : tr[i].cells[11].innerHTML,
            })
        };
        setBondData(tdArr);
    }, []);

    const dispatch = useDispatch();

    const handleTableMode = (e) => {
        e.preventDefault();
        setTableMode(!tableMode);
        if(tableMode === false) {
            dispatch(getBonds());
        };
    };

    let search_result = null;
    if(searchCount){
        search_result = <p style={{textAlign: "center", marginTop: "50px"}}>{searchCount} 개의 검색 결과가 있습니다.</p>;
    }
    
    if(props.data && props.data.length > 0){
        let tr = document.getElementsByTagName("tr");
        return (
            <div className="table-wrapper">
                <div className="table-top-button-wrap">
                    <TableExport data={bondData} />
                    {(tableMode === true) ?
                        <Button onClick={handleTableMode}>채권상태수정</Button> :
                        <Button onClick={handleTableMode}>채권상태수정완료</Button>
                    }
                </div>
                <Table hover size="sm">
                    <TableThread />
                    <tbody>
                        {props.data.map((data) => {
                            return (
                                <tr align="center" key={data.uuid}>
                                    <td>{compareLatest(data.created_time)}</td>
                                    <td>{autoHyphenDate(data.created_time)}</td>
                                    <td>{data.payer_uuid.username}</td>
                                    <td>{autoHyphenPhone(data.payer_uuid.phone)}</td>
                                    <td 
                                        onClick={() => { handlePageMove(data.uuid, data.payer_uuid.id, data.bond_policy.bond_type, data.bond_status.bond_status) }}
                                        style={{cursor: "pointer"}}
                                        >
                                        {userCourse(data.name)}
                                    </td>
                                    <td>{bondType(data.bond_policy.bond_type)}</td>
                                    <td>{autoHyphenDate(data.bond_policy.bond_start_date)}</td>
                                    <td>{autoHyphenDate(data.bond_policy.bond_end_date)}</td>
                                    <td>{bondPercentage(data.bond_policy)}</td>
                                    <td>{getBondPaymentTimes(data)}</td>
                                    <td>{bondDeposit(data.bond_policy)}</td>
                                    {(tableMode) ? 
                                        <td>{bondStatus(data.bond_status)}</td> :
                                        <td>
                                            <ModifyBondStatus bondUuid={data.uuid} bondName={data.name} bondStatus={data.bond_status?.bond_status} />
                                        </td>
                                    }
                                    <td><UploadBondURL id={data.payer_uuid.id} /></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                {search_result}
            </div>
        )
    } else if (props.data && props.data.length <= 0) {
        return (
            <EmptyPage thread={<TableThread />} colspan={13} />
        )
    }
    return (
        <></>
    )
};

export default withRouter(BondTable);