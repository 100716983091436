import * as bondAPI from '../api/bondAPI';
import { 
    GET_BONDS,
    GET_BONDS_SUCCESS,
    GET_BONDS_ERROR
} from './_types';


export const getBonds = (offset, pageLimit, currentPage, pageChunk, props) => async dispatch => {
    dispatch({ type: GET_BONDS }); // 요청 시작
    try {
        const data = await bondAPI.getBonds(offset, pageLimit, currentPage, pageChunk); // API 호출

        if (data[0].results.length > 0) {
            dispatch({ type: GET_BONDS_SUCCESS, data }); // 요청 성공
        } else {
            alert('존재하지 않는 페이지입니다.');
            const data = await bondAPI.getBonds(0, pageLimit, 1, 0); // API 재호출
            dispatch({ type: GET_BONDS_SUCCESS, data }); // 재요청 성공
            props.history.push(`/${props.page}?page=1`); // 1페이지로 redirect
        };

    } catch (e) {
        dispatch({ type: GET_BONDS_ERROR, error: e });
    }
};