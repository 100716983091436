import * as paymentAPI from '../api/paymentAPI';
import { 
    GET_PAYMENT_LIST,
    GET_PAYMENT_LIST_SUCCESS,
    GET_PAYMENT_LIST_ERROR,
    GET_PAYMENT_ONE,
    GET_PAYMENT_ONE_SUCCESS,
    GET_PAYMENT_ONE_ERROR,
} from './_types';


export const getPayments = (offset, pageLimit, currentPage, pageChunk, props) => async dispatch => {
    dispatch({ type: GET_PAYMENT_LIST }); // 요청 시작
    try {
        const data = await paymentAPI.getPaymentPart(offset, pageLimit, currentPage, pageChunk); // API 호출

        if (data[0].results.length > 0) {
            dispatch({ type: GET_PAYMENT_LIST_SUCCESS, data }); // 요청 성공
        } else {
            alert('존재하지 않는 페이지입니다.');
            const data = await paymentAPI.getPaymentPart(0, pageLimit, 1, 0); // API 재호출
            dispatch({ type: GET_PAYMENT_LIST_SUCCESS, data }); // 재요청 성공
            props.history.push(`/${props.page}?page=1`); // 1 페이지로 redirect
        };

    } catch (e) {
        dispatch({ type: GET_PAYMENT_LIST_ERROR, error: e });
    }
};


export const getPaymentsOne = (uuid) => async dispatch => {
    dispatch({ type: GET_PAYMENT_ONE }); // 요청 시작
    try {
        const data = await paymentAPI.getPaymentOne(uuid); // API 호출
        dispatch({ type: GET_PAYMENT_ONE_SUCCESS, data }); // 요청 성공
    } catch (e) {
        dispatch({ type: GET_PAYMENT_ONE_ERROR, error: e });
    }
};