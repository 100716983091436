import * as statusAPI from '../api/statusAPI';
import { 
    GET_STATUS,
    GET_STATUS_SUCCESS,
    GET_STATUS_ERROR
} from './_types';


export const getStatuses = (uuid) => async dispatch => {
    dispatch({ type: GET_STATUS }); // 요청 시작
    try {
        const data = await statusAPI.getStatus(uuid); // API 호출
        dispatch({ type: GET_STATUS_SUCCESS, data }); // 요청 성공
    } catch (e) {
        dispatch({ type: GET_STATUS_ERROR, error: e });
    }
};