import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function PublicRoute({ component: Component, publicRoom, ...rest }) {
    return (
        <Route
            {...rest}
            render = {props =>
                localStorage.getItem('Token')?(
                    <Redirect to={{
                                    pathname: '/board',
                                    state: {reason: '로그인한 사용자'}
                                }}
                    />
                ) : (
                    <Component {...props} getRoom={publicRoom} />
                )
            }
        />
    );
}

export { PublicRoute };