import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './_components/_routes/PrivateRoute';
import { PublicRoute } from './_components/_routes/PublicRoute';
import { useDispatch } from 'react-redux';
import { authCheck } from './_actions/user_action';
import login from './components/LoginPage/login';
import BoardPage from './components/BoardPage/BoardPage';
import BondPage from './components/BoardPage/BondPage';
import EmploymentPage from './components/BoardPage/EmploymentPage';
import IncomePage from './components/BoardPage/IncomePage';
import PaymentPage from './components/BoardPage/PaymentPage';
import TypePage from './components/BoardPage/TypePage';
import MemberPage from './components/BoardPage/MemberPage';
import NotFound404 from './components/BoardPage/NotFound404';


function App(props) {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authCheck());
  },[dispatch]);

  return (
    <div>
      <Router>
        <Switch>
          <PublicRoute exact path="/" component={login} publicRoom={props.publicRoom} />
          <PrivateRoute exact path="/board" component={BoardPage} privateRoom={props.privateRoom} />
          <PrivateRoute exact path="/bond" component={BondPage} privateRoom={props.privateRoom} />
          <PrivateRoute exact path="/bond?page=:page" component={BondPage} privateRoom={props.privateRoom} />
          <PrivateRoute exact path="/bond/detail/:uuid" component={TypePage} privateRoom={props.privateRoom} />
          <PrivateRoute exact path="/employment" component={EmploymentPage} privateRoom={props.privateRoom} />
          <PrivateRoute exact path="/employment?page=:page" component={EmploymentPage} privateRoom={props.privateRoom} />
          <PrivateRoute exact path="/income" component={IncomePage} privateRoom={props.privateRoom} />
          <PrivateRoute exact path="/income?page=:page" component={IncomePage} privateRoom={props.privateRoom} />
          <PrivateRoute exact path="/payment" component={PaymentPage} privateRoom={props.privateRoom} />
          <PrivateRoute exact path="/payment?page=:page" component={PaymentPage} privateRoom={props.privateRoom} />
          <PrivateRoute exact path="/member" component={MemberPage} privateRoom={props.privateRoom} />
          <PrivateRoute exact path="/member?page=:page" component={MemberPage} privateRoom={props.privateRoom} />
          <Route exact path="/*" component={NotFound404} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
