import { 
    SEARCH_START,
    SEARCH_SUCCESS_PAYER,
    SEARCH_SUCCESS_REQUESTER,
    SEARCH_ERROR,
    BOND_SEARCH_START,
    BOND_SEARCH_SUCCESS,
    BOND_SEARCH_ERROR,
    EMPLOY_SEARCH_START,
    EMPLOY_SEARCH_SUCCESS,
    EMPLOY_SEARCH_ERROR,
    INCOME_SEARCH_START,
    INCOME_SEARCH_SUCCESS,
    INCOME_SEARCH_ERROR,
    PAYMENT_SEARCH_START,
    PAYMENT_SEARCH_SUCCESS,
    PAYMENT_SEARCH_ERROR,
    MEMBER_SEARCH_START,
    MEMBER_SEARCH_SUCCESS,
    MEMBER_SEARCH_ERROR,
} from '../_actions/_types';

//초깃값
export const initialState = {
    searches : {
        loading : null,
        data_payer: null,
        data_requester: null,
        error: null,
    },
    bondSearches : {
        loading : null,
        count: null,
        data : null,
        query: null,
        error : null,
        currentPage: 1,
        pageChunk: 0,
    },
    employSearches : {
        loading: null,
        count: null,
        data: null,
        query: null,
        error : null,
        currentPage: 1,
        pageChunk: 0,
    },
    incomeSearches : {
        loading : null,
        count: null,
        data : null,
        query : null,
        error : null,
        currentPage: 1,
        pageChunk: 0,
    },
    paymentSearches : {
        loading : null,
        count: null,
        data : null,
        query: null,
        error : null,
        currentPage: 1,
        pageChunk: 0,
    },
    memberSearches : {
        loading : null,
        count: null,
        data : null,
        query: null,
        currentPage: 1,
        pageChunk: 0,
        error : null,
    },
}

//검색 리듀서
export default function searchesReducer(state = initialState, action) {
    switch (action.type) {
        case SEARCH_START:
            return {
                ...state,
                searches: {
                    loading: 'loading...',
                    data_payer: null,
                    data_requester: null,
                    data_filter: null,
                    error: null
                }
            };
        case SEARCH_SUCCESS_PAYER:
            return {
                ...state,
                searches: {
                    loading: 'loading success...',
                    data_payer: action.data,
                    data_requester: null,
                    error: null
                }
            };
        case SEARCH_SUCCESS_REQUESTER:
            return {
                ...state,
                searches: {
                    loading: 'loading success...',
                    data_payer: null,
                    data_requester: action.data,
                    data_filter: null,
                    error: null
                }
            };
        case SEARCH_ERROR:
            return {
                ...state,
                searches: {
                    loading: 'loading error...',
                    data_payer: null,
                    data_requester: null,
                    data_filter: null,
                    error: action.error
                }
            };
        case BOND_SEARCH_START:
            return {
                ...state,
                bondSearches: {
                    loading: 'loading...',
                    count: null,
                    data: null,
                    error: null
                }
            };
        case BOND_SEARCH_SUCCESS:
            return {
                ...state,
                bondSearches: {
                    loading: 'search success...',
                    count: action.data[0],
                    data: action.data[1],
                    query: action.data[2],
                    currentPage : action.data[3],
                    pageChunk: action.data[4],
                    error: null
                }
            }
        case BOND_SEARCH_ERROR:
            return{
                ...state,
                employSearches: {
                    loading: 'search error...',
                    count: null,
                    data: null,
                    error: action.error
                }
            }
        case EMPLOY_SEARCH_START:
            return {
                ...state,
                employSearches: {
                    loading: 'loading...',
                    count: null,
                    data: null,
                    error: null
                }
            };
        case EMPLOY_SEARCH_SUCCESS:
            return {
                ...state,
                employSearches: {
                    loading: 'search success...',
                    count: action.data[0],
                    data: action.data[1],
                    query: action.data[2],
                    currentPage : action.data[3],
                    pageChunk: action.data[4],
                    error: null
                }
            }
        case EMPLOY_SEARCH_ERROR:
            return{
                ...state,
                employSearches: {
                    loading: 'search error...',
                    count: null,
                    data: null,
                    error: action.error
                }
            }
        case INCOME_SEARCH_START:
            return {
                ...state,
                incomeSearches: {
                    loading: 'loading...',
                    count: null,
                    data: null,
                    error: null
                }
            };
        case INCOME_SEARCH_SUCCESS:
            return {
                ...state,
                incomeSearches: {
                    loading: 'search success...',
                    count: action.data[0].count,
                    data: action.data[0].results,
                    query: action.data[1],
                    currentPage : action.data[2],
                    pageChunk: action.data[3],
                    error: null
                }
            };
        case INCOME_SEARCH_ERROR:
            return{
                ...state,
                incomeSearches: {
                    loading: 'search error...',
                    count: null,
                    data: null,
                    error: action.error
                }
            }
        case PAYMENT_SEARCH_START:
            return {
                ...state,
                paymentSearches: {
                    loading: 'loading...',
                    count: null,
                    data: null,
                    error: null
                }
            };
        case PAYMENT_SEARCH_SUCCESS:
            return {
                ...state,
                paymentSearches: {
                    loading: 'search success...',
                    count: action.data[0],
                    data: action.data[1],
                    query: action.data[2],
                    currentPage : action.data[3],
                    pageChunk: action.data[4],
                    error: null
                }
            }
        case PAYMENT_SEARCH_ERROR:
            return{
                ...state,
                paymentSearches: {
                    loading: 'search error...',
                    count: null,
                    data: null,
                    error: action.error
                }
            }
        case MEMBER_SEARCH_START:
            return {
                ...state,
                memberSearches: {
                    loading: 'loading...',
                    count: null,
                    data: null,
                    error: null
                }
            }
        case MEMBER_SEARCH_SUCCESS:
            return {
                ...state,
                memberSearches: {
                    loading: 'search success...',
                    count: action.data[0],
                    data: action.data[1],
                    query: action.data[2],
                    currentPage: action.data[3],
                    pageChunk: action.data[4],
                    error: null
                }
            }
        case MEMBER_SEARCH_ERROR:
            return{
                ...state,
                memberSearches: {
                    loading: 'search error...',
                    count: null,
                    data: null,
                    error: action.error
                }
            }    
        default:
            return state;
    }
};

