import React from 'react';
import logo2 from '../../assets/img/logo2.png';
import {  } from 'react-icons/ai';
import { FaIdCardAlt, FaMoneyCheckAlt, FaCity, FaCoins, FaEnvelopeOpenText } from 'react-icons/fa';

export default function Sidebar () {
    
    return (
        <div> 
            <nav className="side-bar">
                <a className="logo" href="/board">
                    <img src={logo2} alt="" className="logo" />
                </a>
                <a className="sub-title" href="/member">
                    <div className="nav-icon"><FaIdCardAlt className="fa" /></div>
                    <div className="nav-text">고객</div>
                </a>
                <a className="sub-title" href="/bond">
                    <div className="nav-icon"><FaMoneyCheckAlt className="fa" /></div>
                    <div className="nav-text">채권</div>
                </a>
                <a className="sub-title" href="/employment">
                    <div className="nav-icon"><FaCity className="fa" /></div>
                    <div className="nav-text">취업</div>
                </a>
                <a className="sub-title" href="/income">
                    <div className="nav-icon"><FaCoins className="fa" /></div>
                    <div className="nav-text">급여</div>
                </a>
                <a className="sub-title" href="/payment">
                    <div className="nav-icon"><FaEnvelopeOpenText className="fa" /></div>
                    <div className="nav-text">청구서</div>
                </a>
            </nav>
        </div>
    )

}
