import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../parts/Header';
import Sidebar from '../parts/Sidebar';
import SideHeader from '../parts/SideHeader';
import SearchList from '../../_components/search/searchList';
import Paginations from '../parts/Paginations';
import { FooterBoard } from '../parts/Footer';
import IncomeSearch from '../../_components/search/searchParts/incomeSearch';
import IncomeList from '../../_components/income/IncomeList';
import { getIncomes } from '../../_actions/income_action';
import { getIncomeSearch } from '../../_actions/search_action';


const IncomePage = (props) => {

    const INCOME = 'income';
    const { mode } = useSelector(state => state.modes);
    const { count } = useSelector(state => state.income.incomes);
    const { incomes } = useSelector(state => state.income);
    const incomeCurrentPage = useSelector(state => state.income.incomes.currentPage);
    const incomeCurrentPageChunk = useSelector(state => state.income.incomes.pageChunk);

    const searchCount = useSelector(state => state.searches.incomeSearches.count);
    const searchQuery = useSelector(state => state.searches.incomeSearches.query);
    const searchCurrentPage = useSelector(state => state.searches.incomeSearches.currentPage);
    const searchPageChunk = useSelector(state => state.searches.incomeSearches.pageChunk);

    let contents;
    
    //URLSearchParams로 react-router의 query String을 object화
    let search = new URLSearchParams(props.location.search);

    //get 메소드로 해당 key 값 조회 (?page=1 일 시 page가 key가 되고 1이 value)
    let pageNum = parseInt(search.get('page')) || 1;

    if (mode === 'read') {
        contents = <div className="main-content">
            <SideHeader big="INCOME" small="급여 목록" />
            <IncomeSearch />
            <IncomeList data={incomes} />
            <Paginations 
                page={INCOME} 
                count={count} 
                pageNum={pageNum}
                currentPage={incomeCurrentPage} 
                pageChunk={incomeCurrentPageChunk}
                dispatch={getIncomes}
            />
        </div>
    } else if (mode === 'search') {
        contents = <div className="main-content">
            <SideHeader big="INCOME" small="급여 검색 결과"/>
            <IncomeSearch />
            <SearchList page={INCOME}/>
            <Paginations 
                page={INCOME} 
                pageNum={pageNum}
                count={searchCount} 
                query={searchQuery}
                currentPage={searchCurrentPage} 
                pageChunk={searchPageChunk}
                dispatch={getIncomeSearch}
            />
        </div>
    };
        
    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            {contents}
            <FooterBoard />
        </div>
    );

};

export default withRouter(IncomePage);
