import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postLogin } from '../../_actions/user_action';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import logo3 from '../../assets/img/logo3.png';

const Login = () => {

    const { isLoggedIn, error } = useSelector(state => state.users.data);

    const dispatch = useDispatch();

    const [inputs, setInputs] = useState({
        username: '',
        password: ''
    })

    const handleChange = (e) => {
        const { value, name } = e.target;
        setInputs({
            ...inputs,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(postLogin(inputs));
    }

    return (
        <div className="login-page">
            <div className="login-box">
                <form 
                    className="login-form"
                    onSubmit={handleSubmit}
                >
                    <img src={logo3} alt="" className="logo3" />
                    <input  
                        type="email" 
                        name="username" 
                        onChange={handleChange}
                        placeholder="이메일을 입력해주세요" 
                    />
                    <input  
                        type="password" 
                        name="password" 
                        onChange={handleChange}
                        placeholder="비밀번호를 입력해주세요" 
                    />
                    <Button type="submit" className="login-button">로그인</Button>
                    { isLoggedIn === false && error && <p className="error-message">로그인에 실패하였습니다. 이메일과 비밀번호를 확인해주세요.</p> }
                </form>
            </div>
        </div>
    )

}



export default withRouter(Login);