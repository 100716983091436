import Axios from 'axios';
import {URL_PRESENT} from './URL';
import { headerConfig } from './cookie/common';

//회원 데이터를 가져오는 api
export const getMembers = async (offset, pageLimit, currentPage, pageChunk) => {
    const response = await Axios.get(
        `${URL_PRESENT}/member//?offset=${offset}&limit=${pageLimit}&ordering=-party__date_joined`, 
        headerConfig()
    );
    let data = [];
    data.push(response.data, currentPage, pageChunk);
    return data;
};
