import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute({ component: Component, privateRoom, ...rest }) {
    return (
        <Route
            {...rest}
            render = {props =>
                localStorage.getItem('Token')?(
                    <Component {...props} privateRoom={privateRoom} />
                ) : (
                    <Redirect to={{
                                    pathname: '/',
                                    state: {reason: '관리자만 접근 가능한 페이지입니다.'}
                                }}
                    />
                )
            }
        />
    );
}
  
export { PrivateRoute };