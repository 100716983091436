import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../api/employmentAPI';
import { getBondOne } from '../../api/bondAPI';
import { commonCost, autoHyphenPhone, autoHyphenDate, userCourse, genderTrans, addressTrans } from '../common/common';
import TypeComponent, {TypeInterestsComponent} from './typeParts/TypeComponent';
import { depositPolicyType, interestPolicy, interestRate, paymentListCount } from './typeParts/typeFunc';


const TypeGeneral = (props) => {

    const [userInfo, setUserInfo] = useState('');
    const [userBond, setUserBond] = useState('');
    const {data} = useSelector(state => state.status.statuses);

    const loadData = async () => {
        const data = await getUserInfo(props.data.id); 
        setUserInfo(data);
    };

    const loadDataTwo = async () => {
        const data = await getBondOne(props.data.uuid);  
        setUserBond(data);
    }

    useEffect(() => { loadData() }, []);
    useEffect(() => { loadDataTwo() }, []);

    if(userBond && userInfo) {
        let { bond_start_date, bond_end_date, deposit_policy, interest_policy, total_payment_amount } = userBond.bond_policy;
        return(
            <Fragment>
                <div className="general-form profile-info">
                    <div className="photo-form profile"></div>
                    <div className="info-form">
                        <h5>납부자 정보</h5>
                        <TypeComponent name="이름" function={userInfo.party.username} />
                        <TypeComponent name="성별" function={genderTrans(userInfo.gender)} />
                        <TypeComponent name="생년월일" function={userInfo.birthday} />
                        <TypeComponent name="이메일" function={userInfo.party.email} />
                        <TypeComponent name="연락처" function={autoHyphenPhone(userInfo.party.phone)} />
                        <TypeComponent name="주소" function={addressTrans(userInfo.address)} />
                    </div>
                </div>
    
                <div className="general-form profile-info">
                    <div className="info-form profile2">
                        <h5>채권정보</h5>
                        <TypeComponent name="채권 유형" function="일반(후불제)" />
                        <TypeComponent name="과정 명" function={userCourse(userBond.name)} />
                        <TypeComponent name="채권 시작일" function={autoHyphenDate(bond_start_date)} />
                        <TypeComponent name="채권 종료일" function={autoHyphenDate(bond_end_date)} />
                        <TypeComponent name="횟수(회)" function={paymentListCount(data)} />
                        <TypeComponent name="총 납부 금액(원)" function={commonCost(total_payment_amount)} />
                        <TypeComponent name="보증금 정책" function={depositPolicyType(deposit_policy)} />
                        {(deposit_policy) ? 
                            <TypeComponent name="보증금(원)" function={commonCost(deposit_policy?.deposit)} /> :
                            <TypeComponent name="보증금(원)" function={null} />
                        }
                        <TypeComponent name="이자율 정책" function={interestPolicy(interest_policy)} />
                        <TypeInterestsComponent function={interestRate(interest_policy)} />
                    </div>
                </div>
            </Fragment>
        )
    }
    
    return(
        <>
        </>
    )
}

export default TypeGeneral;