import { Button } from 'reactstrap';
import { ExcelFile, ExcelSheet, ExcelColumn } from '../../components/parts/excelExports';

//청구서 상태 출력 함수
export const paymentStatus = (status) => {
    switch (status && status?.scheduled_payment_status) {
        case 1:
            return '초기화';
        case 2:
            return '납부 진행중';
        case 3:
            return '납부 완료';
        case 4:
            return <span style={{color:'red'}}>납부 연체</span>;
        case 5:
            return '납부 완료(연체)';
        case 6:
            return <span style={{color:'red'}}>납부 연체(추심)</span>;
        case 7:
            return '강제 종료';
        case 8:
            return '무효';
        case 9:
            return '포기';
        case 10:
            return '알 수 없음';
        case 11 :
            return '미납';
        default:
            return '알 수 없음';
    }
}

//청구서 타입 출력 함수
export const paymentType = (type) => {
    switch (type) {
        case 1:
            return '일반 납부';
        case 2:
            return '선납';
        case 3:
            return '최대 금액 납부';
        case 4:
            return '보증금';
        case 5:
            return '서비스 이용료';
        case 6:
            return '기타';
        default:
            return '';
    }
}

export const TableThread = () => {
    return(
        <thead>
            <tr align="center">
                <td></td>
                <td>등록일</td>
                <td>이름</td> 
                <td>연락처</td>
                <td>과정명</td>
                <td>납부유형</td> 
                <td>회차</td>
                <td>납부기한</td>
                <td>납부일</td>
                <td>납부금액</td>
                <td>납부상태</td>
                <td>연체기간</td>
            </tr> 
        </thead>
    )
};

export const TableExport = ({data}) => {
    return (
        <ExcelFile element={<Button color="success" style={{marginBottom:'30px'}}>Export Excel</Button>}>
            <ExcelSheet data={data} name="청구서 리스트">
                <ExcelColumn label="등록일" value="createdTime" />
                <ExcelColumn label="이름" value="name"/>
                <ExcelColumn label="연락처" value="phone"/>
                <ExcelColumn label="과정명" value="course" />
                <ExcelColumn label="납부유형" value="scheduledPaymentType" />
                <ExcelColumn label="회차" value="paymentTurn" />
                <ExcelColumn label="납부기한" value="paymentDueDate" />
                <ExcelColumn label="납부일" value="paymentRealDate" />
                <ExcelColumn label="납부금액" value="paymentAmount" />
                <ExcelColumn label="납부상태" value="scheduledPaymentStatus" />
                <ExcelColumn label="연체기간" value="paymentDueDateSince" />
            </ExcelSheet>
        </ExcelFile>
    );
}