import Axios from 'axios';
import { URL_PRESENT } from './URL';
import { headerConfig } from './cookie/common';

//login api
export const postLogin = async(userData) => {
    const response = await Axios.post(`${URL_PRESENT}/auth/login/`, userData);
    return response.data;
};

//logout api
export const postLogout = async() => {
    const response = await Axios.post(`${URL_PRESENT}/auth/logout/`);
    return response.data;
};

//현재 로그인한 회원 정보를 대조하는 api
export const authCheck = async() => {
    const response = await Axios.get(`${URL_PRESENT}/auth/user/`, headerConfig());
    return response.data;
};