import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux'; 
import Header from '../parts/Header';
import Sidebar from '../parts/Sidebar';
import SideHeader from '../parts/SideHeader';
import EmploySearch from '../../_components/search/searchParts/employSearch';
import EmploymentList from '../../_components/employment/EmploymentList';
import Paginations from '../parts/Paginations';
import { FooterBoard } from '../parts/Footer';
import SearchList from '../../_components/search/searchList';
import { getEmploymentParts } from '../../_actions/employment_action';
import { getEmploySearch } from '../../_actions/search_action';


const EmploymentPage = (props) => {
    
    const EMPLOYMENT = 'employment';
    const { mode } = useSelector(state => state.modes);
    const { count } = useSelector(state => state.employment.employments);
    const { employments } = useSelector(state => state.employment);
    const employmentCurrentPage = useSelector(state => state.employment.employments.currentPage);
    const employmentPageChunk = useSelector(state => state.employment.employments.pageChunk);
    
    const searchCount = useSelector(state => state.searches.employSearches.count);
    const searchQuery = useSelector(state => state.searches.employSearches.query);
    const searchCurrentPage = useSelector(state => state.searches.employSearches.currentPage);
    const searchPageChunk = useSelector(state => state.searches.employSearches.pageChunk);
    
    let contents;

    //URLSearchParams로 react-router의 query String을 object화
    let search = new URLSearchParams(props.location.search);

    //get 메소드로 해당 key 값 조회 (?page=1 일 시 page가 key가 되고 1이 value)
    let pageNum = parseInt(search.get('page')) || 1;

    if (mode === 'read') {
        contents = <div className="main-content">
            <SideHeader big="Employment" small="취업 목록" />
            <EmploySearch />
            <EmploymentList data={employments} />
            <Paginations 
                count={count} 
                page={EMPLOYMENT} 
                pageNum={pageNum}
                currentPage={employmentCurrentPage} 
                pageChunk={employmentPageChunk}
                dispatch={getEmploymentParts}
            />
        </div>
    } else if (mode === 'search') {
        contents = <div className="main-content">
            <SideHeader big="Employment" small="취업 목록 검색 결과" />
            <EmploySearch />
            <SearchList page={EMPLOYMENT} />
            <Paginations 
                page={EMPLOYMENT} 
                pageNum={pageNum} 
                count={searchCount} 
                query={searchQuery}
                currentPage={searchCurrentPage} 
                pageChunk={searchPageChunk}
                dispatch={getEmploySearch}
            />
        </div>
    };


    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            {contents}
            <FooterBoard />
        </div>
    );

};

export default withRouter(EmploymentPage);