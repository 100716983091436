import React from 'react';
import { useDispatch } from 'react-redux';
import { postLogout } from '../../_actions/user_action';
import { withRouter } from 'react-router-dom';
import UserInfo from '../parts/UserInfo';


const Logout = () => {

    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(postLogout());
    }
    
    return (
        <div className="logout-box">
            <UserInfo />
            <button onClick={handleSubmit} className="logout-btn" >
                <i className="fa fa-lock" ></i>
            </button>
        </div>
    )

}

export default withRouter(Logout);