import React, {useState} from 'react';
import { Button, Form, Col, Row, Label } from 'reactstrap';
import DatePicker from 'react-date-picker';
import { removeHypen, pageSlice, pageLimit } from '../../common/common';
import { useDispatch } from 'react-redux';
import { getMemberSearch } from '../../../_actions/search_action';
import { initialState } from '../../../_reducers/search_reducer';
import { getMode } from '../../../_actions/mode_action';
import { withRouter } from 'react-router';



const MemberSearch = (props) => {

    const dispatch = useDispatch();
    const [isActive, setIsActive] = useState(true);
    const [side, setSide] = useState(false);
    const [party__username, setPartyUsername] = useState('');
    const [party__phone, setPartyPhone] = useState('');
    const [party__email, setPartyEmail] = useState('');
    const [gender, setGender] = useState('');
    const [address, setAddress] = useState('');
    const [birthday_lowerbound, setBirthDayLowerbound] = useState('');
    const [birthday_upperbound, setBirthDayUpperbound] = useState('');
    const [party__date_joined_lowerbound, setDateJoinedLowerBound] = useState('');
    const [party__date_joined_upperbound, setDateJoinedUpperBound] = useState('');
    const { currentPage, pageChunk } = initialState.memberSearches;

    const [query_array, setQuerayArray] = useState({
        party__username: '',
        party__phone: '',
        party__email: '',
        gender: '',
        address: '',
        birthday_lowerbound: '',
        birthday_upperbound: '',
        party__date_joined_lowerbound: '',
        party__date_joined_upperbound: '',
    })

    const handleActive = () => {
        setIsActive(!isActive);
        setSide(!side);
    }

    const handleOnChange = (e) => {
        e.preventDefault();
        let { name, value } = e.target;

        if (name === 'party__username') {
            setPartyUsername(value);
        } else if (name === 'party__phone') {
            setPartyPhone(value);
        } else if (name === 'party__email') {
            setPartyEmail(value);
        } else if (name === 'gender') {
            setGender(value);
        } else if (name === 'address') {
            setAddress(value);
        }

        setQuerayArray({
            ...query_array,
            [name] : value
        });
    }

    const renderDayContents = (day, name) => {
        
        let year = new Date(day).getFullYear();
        let month = new Date(day).getMonth() + 1;
        let date = new Date(day).getDate();

        if(name.indexOf('_lowerbound') !== -1){
            setQuerayArray({
                ...query_array,
                [name] : `${year}-${month}-${date}T00:00:00`
            });
        } else if(name.indexOf('_upperbound') !== -1) {
            setQuerayArray({
                ...query_array,
                [name] : `${year}-${month}-${date}T23:59:59`
            });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const arr = [];
        for(let key in query_array){
            if(query_array[key]){
                if(key === 'party__phone') {
                    arr.push(`${key}=${removeHypen(query_array[key])}`);
                } else if (key === 'gender') {
                    if(query_array[key] !== 'null') {
                        arr.push(`${key}=${query_array[key]}`);
                    }
                } else {
                    arr.push(`${key}=${query_array[key]}`);
                }
            }
        };
        dispatch(getMemberSearch(arr.join('&'), pageSlice, pageLimit, currentPage, pageChunk));
        dispatch(getMode('search'));
        props.history.push(`/member?page=1`);
    };

    const handleReset = (e) => {
        e.preventDefault();
        let { name } = e.target;
        setPartyUsername('');
        setPartyPhone('');
        setPartyEmail('');
        setAddress('');
        setGender('null');
        setBirthDayLowerbound('');
        setBirthDayUpperbound('');
        setDateJoinedLowerBound('');
        setDateJoinedUpperBound('');

        setQuerayArray({
            [name] : '',
        })

    }

    return (
        <div className="search-box">
            <div style={{display:'grid'}}>
                <div className="search-sub">
                    <h3 className="search-title">
                        <div onClick={handleActive} className={`arrow ${side ? "up" : "down"}`}></div>
                    </h3>
                    <hr className="search-hr"/>
                </div>
                <Form 
                    className={`form-table ${isActive ? "active" : "notActive"}`}
                    onSubmit={handleSubmit}
                    onReset={handleReset}
                >
                    <Row>
                        <Col>
                            <Label for="party_usename">이름</Label>
                            <input
                                name="party__username" 
                                value={party__username}
                                onChange={handleOnChange}
                                type="string" 
                            />
                        </Col>
                        <Col>
                            <Label for="party_phone">연락처</Label>
                            <input 
                                name="party__phone"
                                value={party__phone}
                                onChange={handleOnChange}
                                type="tel"
                                pattern="[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}"
                            />
                        </Col>
                        <Col>
                            <Label for="gender">성별</Label>
                            <select 
                                name="gender"
                                value={gender}
                                onChange={handleOnChange}
                            >
                                <option value="null">선택</option>
                                <option value="Female">여성</option>
                                <option value="Male">남성</option>
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label for="address">주소</Label>
                            <input 
                                name="address"
                                value={address}
                                onChange={handleOnChange}
                                type="string"
                            />
                        </Col>
                        <Col>
                            <Label for="party__email">이메일</Label>
                            <input 
                                name="party__email"
                                value={party__email}
                                onChange={handleOnChange}
                                type="email" 
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label for="birthday">생일</Label>
                            <DatePicker
                                name="birthday_lowerbound"
                                value={birthday_lowerbound}
                                clearIcon={null}
                                onChange={(date) => {setBirthDayLowerbound(date); renderDayContents(date, 'birthday_lowerbound'); }}
                            />
                            <span> ~ </span>
                            <DatePicker
                                name="birthday_upperbound"
                                value={birthday_upperbound}
                                clearIcon={null}
                                onChange={(date) => {setBirthDayUpperbound(date); renderDayContents(date, 'birthday_upperbound'); }}
                            />
                        </Col>
                        <Col>
                            <Label for="valid_to">등록일</Label>
                            <DatePicker
                                name="party__date_joined_lowerbound"
                                value={party__date_joined_lowerbound}
                                clearIcon={null}
                                onChange={date => {setDateJoinedLowerBound(date); renderDayContents(date, 'party__date_joined_lowerbound'); }}
                            />
                            <span> ~ </span>
                            <DatePicker
                                name="party__date_joined_upperbound"
                                value={party__date_joined_upperbound}
                                clearIcon={null}
                                onChange={date => {setDateJoinedUpperBound(date); renderDayContents(date, 'party__date_joined_upperbound'); }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <div className="search-button-wrapper">
                            <Button color="success" className="search-submit" type="submit">검색하기</Button>
                            <Button color="white" className="search-reset" type="reset">초기화</Button>
                        </div>
                    </Row>
                </Form>
            </div>
        </div>
    )
};

export default withRouter(MemberSearch);

