import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getStatuses } from '../../_actions/status_action';
import Header from '../parts/Header';
import Sidebar from '../parts/Sidebar';
import SideHeader from '../parts/SideHeader';
import TypePaymentList from '../../_components/type/TypePaymentList';
import { FooterBoard } from '../parts/Footer';
import TypeISA from '../../_components/type/TypeISA';
import TypePrepaid from '../../_components/type/TypePrepaid';
import TypeGeneral from '../../_components/type/TypeGeneral';
import { getMode } from '../../_actions/mode_action';

const TypePage = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const { mode } = useSelector(state => state.modes);
    const data = {
        uuid: location.state._uuid,
        id: location.state._id,
        bondType: location.state._bondType,
        bondStatus: location.state._bondStatus,
    };
    let infoContents = null;

    useEffect(() => {
        dispatch(getStatuses(data.uuid));
    }, [data.uuid, dispatch]);

    const handleModeControl = (e) => {
        e.preventDefault();
        dispatch(getMode('update'));
    };

    if(data.bondType === 'PREPAID'){
        infoContents = <TypePrepaid data={data} />;
    } else if (data.bondType === 'ISA') {
        infoContents = <TypeISA data={data} />
    } else if (data.bondType === 'GENERAL') {
        infoContents = <TypeGeneral data={data} />;
    };

    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            <div className="main-content">
                <SideHeader big="BOND INFOMATION" small="채권 상세 페이지" />
                { infoContents }
                <div style={{marginTop: "50px"}}>
                    <div className="status-wrapper">
                        <h5 className="scp-title">청구서 목록 
                            <button className={mode==="read" ? "modify-scpstatus-button" : "modify-scpstatus-button-active"} onClick={handleModeControl}>수정</button></h5>
                        <TypePaymentList statusData={data} mode={mode} />
                    </div>
                </div>
            </div>
            <FooterBoard />
        </div>
    );
};

export default withRouter(TypePage);