import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { uploadURL } from '../../../api/fileAPI';
import logo from '../../../assets/img/logo3.png'

const UploadBondURL = (props) => {

    const [url, setURL] = useState('');
    const [modal, setModal] = useState(false);
    let content;
    
    const toggle = () => setModal(!modal);

    const handleUpload = async () => {
        let data = await uploadURL(props.id);
        if(data){
            let url = `https://console.hakdokman.com/pay/workcontract/${data.token}`;
            setURL(url);
            let obj = document.getElementById("copyToURL");
            obj.select();    
        };
    };

    //url 복사하게 하는 함수
    const textCopy = () => {
        let obj = document.getElementById("copyToURL");
        obj.select();
        let success = document.execCommand("copy");
        obj.setSelectionRange(0, 0);
        if(success) {
            alert('복사되었습니다.');
        };
    };

    const handleChange = (e) => {
        let { value } = e.target;
        setURL(value);
    };

    //url이 state로 들어왔을 시
    if(url){
        content= 
        <div>
            <img src={logo} className="url-logo" />
            <div className="url-box">
                <input type="text" value={url} onChange={handleChange} id="copyToURL" autoFocus />
                <button onClick={textCopy}>URL복사</button>
            </div>
        </div>
    };

    return(
        <>
            <Button color="success" size="sm" onClick={() => {toggle(); handleUpload();}}>URL 발행</Button>
            <Modal isOpen={modal} fade={false} toggle={toggle} className="modal-dialog-centered" >
                <ModalHeader toggle={toggle} className="url-modal-header"></ModalHeader>
                <ModalBody className="url-modal-body">
                    {content}
                </ModalBody>
                <ModalFooter className="url-modal-footer" />
            </Modal>
            
        </>
    );
};

export default UploadBondURL;