import { 
    GET_EMPLOYMENTS_LIST,
    GET_EMPLOYMENTS_LIST_SUCCESS,
    GET_EMPLOYMENTS_LIST_ERROR,
} from '../_actions/_types';

//초깃값
const initialState = {
    employments : {
        loading : null,
        count: null,
        data: [],
        error: null,
        currentPage: 1,
        pageChunk: 0,
    }
}

//근로 리듀서
export default function employmentReducer(state = initialState, action) {
    switch (action.type) {
        case GET_EMPLOYMENTS_LIST:
            return {
                ...state,
                employments: {
                    loading: 'loading...',
                    count: null,
                    data: null,
                    error: null,
                }
            };
        case GET_EMPLOYMENTS_LIST_SUCCESS:
            return {
                ...state,
                employments: {
                    loading: 'loading success...',
                    count: action.data[0].count,
                    data: action.data[0].results,
                    currentPage: action.data[1],
                    pageChunk: action.data[2],
                    error: null,
                }
            };
        case GET_EMPLOYMENTS_LIST_ERROR:
            return {
                ...state,
                employments: {
                    loading: 'loading error...',
                    count: null,
                    data: null,
                    error: action.error,
                }
            };
        default:
            return state;
    }
};

