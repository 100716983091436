import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getPaymentsOne } from '../../../_actions/payment_actions';
import LoadingContent from '../../common/LoadingContent';
import { commonCost, commonDateCal, autoHyphenDate } from '../../common/common';
import { paymentStatus, paymentType } from '../../payment/PaymentFunc';

//청구서 상세 정보를 보여주는 modal
const IncomePayInfo = (props) => {
  
  const [modal, setModal] = useState(false);
  const { loading, data, error } = useSelector((state) => state.payment.payment);
  let payment_uuid = String(props.payment_uuid);
  let contents = null;
  
  const dispatch = useDispatch();
  
  const toggle = () => setModal(!modal);
  
  const viewInfo = async () => {
    dispatch(getPaymentsOne(payment_uuid));
  };

  if(loading === 'loading...' && !data) {
    contents = <LoadingContent />
  } else if (loading === 'loading success...' && data) {
    let payment_status = data.scheduled_payment_status;
    contents = <>
        <p>납부 회차 : {data.payment_turn}</p>
        <p>납부 유형 : {paymentType(data.scheduled_payment_type)}</p>
        <p>납부 상태 : {paymentStatus(payment_status)}</p>
        <p>납부 금액 : {commonCost(data.payment_amount)}원</p>
        <p>납부 기한 : {data?.payment_due_date}</p>
        <p>납부 일자 : {(payment_status && payment_status?.scheduled_payment_status === 3) ? autoHyphenDate(payment_status?.valid_from) : ''}</p>
        <p>연체 기간 : {([4,6].includes(payment_status?.scheduled_payment_status)) ? `${commonDateCal(data?.payment_due_date)}일` : ''}</p>
    </>
  } else if(error) {
    contents = <p style={{textAlign: 'center'}}>해당 청구서의 내용이 존재하지 않습니다.</p>
  }
  
  return (
      <div>
      <Button color="success" size="sm" onClick={() => {toggle(); viewInfo();}}>보기</Button>
      <Modal isOpen={modal} fade={false} toggle={toggle} className="modal-dialog-centered">
          <ModalHeader toggle={toggle}>청구서 정보</ModalHeader>
          <ModalBody>
            {contents}
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
      </Modal>
      </div>
  );
}

export default IncomePayInfo;