import React from 'react';

export default function SideHeader (props) {
    return (
        <div className="side-header">
            <h1 className="contents-title">{props.big}</h1>
            <hr />
            <h5>{props.small}</h5>
        </div>
    )
}
