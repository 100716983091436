import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import EmploymentTable from './EmploymentTable';
import LoadingContent from '../common/LoadingContent';

const EmploymentList = (props) => {

    const { mode } = useSelector(state => state.modes);
    let { loading, data, error } = props.data;
    let contents = null;


    if(loading === 'loading...' && !data) {
        contents = <LoadingContent />
    } else if (loading === 'loading success...' && data && mode === 'read') {
        contents = <>
            <EmploymentTable data={data} />
        </>
    } else if (error) {
        contents = <div className="main-content">
            <div className="null-wrapper">
                <div className="null"></div>
                <p>Error!</p>
            </div>
        </div>
    } else if (!data) {
        contents = <div className="main-content">
            <div className="null-wrapper">
                <div className="null"></div>
                <p>Data is Null</p>
            </div>
        </div>
    };

    return (
        <>
            {contents}
        </>
    );

};

export default withRouter(EmploymentList);