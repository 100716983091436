import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../parts/Header';
import Sidebar from '../parts/Sidebar';
import SideHeader from '../../components/parts/SideHeader';
import { FooterBoard } from '../parts/Footer';
import BondsList from '../../_components/bonds/BondsList';
import Paginations from '../parts/Paginations';
import BondSearch from '../../_components/search/searchParts/bondSearch'
import SearchList from '../../_components/search/searchList';
import { getBonds } from '../../_actions/bond_action';
import { getBondSearch } from '../../_actions/search_action';


const Bond = (props) => {

    const BOND = 'bond';
    const { mode } = useSelector(state => state.modes);
    const { bonds } = useSelector(state => state.bonds);
    const { count } = useSelector(state => state.bonds.bonds);
    const bondCurrentPage = useSelector(state => state.bonds.bonds.currentPage);
    const bondPageChunk = useSelector(state => state.bonds.bonds.pageChunk);
    
    const searchCurrentPage = useSelector(state => state.searches.bondSearches.currentPage);
    const searchPageChunk = useSelector(state => state.searches.bondSearches.pageChunk);
    const searchCount = useSelector(state => state.searches.bondSearches.count);
    const searchQuery = useSelector(state => state.searches.bondSearches.query);
    
    let contents;
    
    //URLSearchParams로 react-router의 query String을 object화
    let search = new URLSearchParams(props.location.search);
    
    //get 메소드로 해당 key 값 조회 (?page=1 일 시 page가 key가 되고 1이 value)
    let pageNum = parseInt(search.get('page')) || 1;


    if (mode === 'read') {
        contents = <div className="main-content">
            <SideHeader big="BOND" small="채권 목록" />
            <BondSearch />
            <BondsList data={bonds} />
            <Paginations 
                page={BOND} 
                count={count}
                pageNum={pageNum}
                currentPage={bondCurrentPage} 
                pageChunk={bondPageChunk}
                dispatch={getBonds}
            />
        </div>
    } else if (mode === 'search') {
        contents = <div className="main-content">
            <SideHeader big="BOND" small="채권 검색 결과"/>
            <BondSearch />
            <SearchList page={BOND}/>
            <Paginations
                page={BOND} 
                pageNum={pageNum}
                count={searchCount}  
                query={searchQuery}
                currentPage={searchCurrentPage} 
                pageChunk={searchPageChunk}
                dispatch={getBondSearch}
            />
        </div>
    };

    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            {contents}
            <FooterBoard />
        </div>
    );

};

export default withRouter(Bond);