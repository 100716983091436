import React, {useState} from 'react';
import { Button, Form, Col, Row, Label } from 'reactstrap';
import DatePicker from 'react-date-picker';
import { removeHypen, pageSlice, pageLimit, daysIntervalCal, autoHyphenDate } from '../../common/common';
import { useDispatch } from 'react-redux';
import { getBondSearch } from '../../../_actions/search_action';
import { initialState } from '../../../_reducers/search_reducer';
import { getMode } from '../../../_actions/mode_action';
import { withRouter } from 'react-router';

const BondSearch = (props) => {

    const dispatch = useDispatch();
    const [isActive, setIsActive] = useState(true);
    const [side, setSide] = useState(false);
    const [payer_uuid__username, setPayerUsername] = useState('');
    const [payer_uuid__phone, setPayerPhone] = useState('');
    const [bond_policy__bond_type, setBondType] = useState('');
    const [bondstatus__bond_status, setBondStatus] = useState('');
    const [bond_policy__deposit_policy__type, setDepositType] = useState('');
    const [bond_name, setBondName] = useState('');
    const [bond_policy__bond_start_date_lowerbound, setBondDateStartLowerBound] = useState('');
    const [bond_policy__bond_start_date_upperbound, setBondDateStartUpperBound] = useState('');
    const [bond_policy__bond_end_date_lowerbound, setBondDateEndLowerBound] = useState('');
    const [bond_policy__bond_end_date_upperbound, setBondDateEndUpperBound] = useState('');
    const [created_time_lowerbound, setCreatedTimeLowerBound] = useState('');
    const [created_time_upperbound, setCreatedTimeUpperBound] = useState('');
    const { currentPage, pageChunk } = initialState.bondSearches;


    const [query_array, setQuerayArray] = useState({
        payer_uuid__username: '',
        payer_uuid__phone: '',
        bond_policy__bond_type: '',
        bond_policy__deposit_policy__type: '',
        name: '',
        bond_policy__bond_end_date_lowerbound: '',
        bond_policy__bond_end_date_upperbound: '',
        bond_policy__bond_start_date_lowerbound: '',
        bond_policy__bond_start_date_upperbound: '',
        bondstatus__bond_status: '',
        created_time_lowerbound: '',
        created_time_upperbound: '',
    });

    const handleActive = () => {
        setIsActive(!isActive);
        setSide(!side);
    };

    const handleInputChange = (e) => {
        e.preventDefault();
        let { name, value } = e.target;

        if (name === 'payer_uuid__username') {
            setPayerUsername(value);
        } else if (name === 'payer_uuid__phone') {
            setPayerPhone(value);
        } else if (name === 'bond_policy__bond_type') {
            setBondType(value);
        } else if (name === 'bond_policy__deposit_policy__type') {
            setDepositType(value);
        } else if (name === 'name') {
            setBondName(value);
        } else if (name === 'bondstatus__bond_status') {
            setBondStatus(value);
        }

        setQuerayArray({
            ...query_array,
            [name] : value
        });
    };

    const querySet = (value1, value2, func1, func2 ) => {
        setQuerayArray({
            ...query_array,
            [value1]: `${autoHyphenDate(func1)}T00:00:00`,
            [value2]: `${autoHyphenDate(func2)}T23:59:59`,
        });
    }

    const querySet2 = (name,year,month,date,value) => {
        if(name.indexOf('_date_') !== -1){
            setQuerayArray({
                ...query_array,
                [name] : `${year}${month}${date}`
            });
        } else if (name.indexOf('_time_') !== -1){
            setQuerayArray({
                ...query_array,
                [name] : `${year}-${month}-${date}${value}`
            });
        }
    }

    const renderDayContents = (day, name) => {
        
        let year = new Date(day).getFullYear();
        let month = new Date(day).getMonth() + 1;
        let date = new Date(day).getDate();

        if(month < 10){
            month = `0${month}`;
        };

        if(date < 10) {
            date = `0${date}`;
        };
        
        if(name.indexOf('_date_lowerbound') !== -1){
            querySet2(name, year, month, date, null);
        } else if(name.indexOf('_date_upperbound') !== -1) {
            querySet2(name, year, month, date, null);
        } else if(name.indexOf('_time_lowerbound') !== -1) {
            querySet2(name, year, month, date, 'T00:00:00');
        } else if (name.indexOf('_time_upperbound') !== -1) {
            querySet2(name, year, month, date, 'T23:59:59');
        }

    };

    const handleDate = (e) => {
        e.preventDefault();
        let { value } = e.target;
        setCreatedTimeUpperBound(new Date());
        setCreatedTimeLowerBound(daysIntervalCal(value));
        querySet('created_time_lowerbound', 'created_time_upperbound', daysIntervalCal(value), daysIntervalCal(0));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const arr = [];
        for(let key in query_array){
            if(query_array[key]){
                if(key === 'payer_uuid__phone') {
                    arr.push(`${key}=${removeHypen(query_array[key])}`);
                } else if (key.indexOf('_type') !== -1) {
                    if(query_array[key] !== 'null') {
                        arr.push(`${key}=${query_array[key]}`);
                    }
                } else if (key.indexOf('_status') !== -1) {
                    if(query_array[key] !== 'null') {
                        arr.push(`${key}=${query_array[key]}`);
                    }
                } else {
                    arr.push(`${key}=${query_array[key]}`);
                }
            }
        };
        dispatch(getBondSearch(arr.join('&'), pageSlice, pageLimit, currentPage, pageChunk));
        dispatch(getMode('search'));
        props.history.push('/bond?page=1');
    };


    const handleReset = (e) => {
        e.preventDefault();
        let { name } = e.target;
        setPayerUsername('');
        setPayerPhone('');
        setBondType('');
        setDepositType('');
        setBondName('');
        setBondStatus('');
        setBondDateStartLowerBound('');
        setBondDateStartUpperBound('');
        setBondDateEndLowerBound('');
        setBondDateEndUpperBound('');
        setCreatedTimeLowerBound('');
        setCreatedTimeUpperBound('');

        setQuerayArray({
            [name] : '',
        })
    }

    return (
        <div className="search-box">
            <div style={{display:'grid'}}>
                <div className="search-sub">
                    <h3 className="search-title"><div onClick={handleActive} className={`arrow ${side ? "up" : "down"}`}></div></h3>
                    <hr className="search-hr"/>
                </div>
                <Form 
                    className={`form-table ${isActive ? "active" : "notActive"}`} 
                    onSubmit={handleSubmit}
                    onReset={handleReset}
                >
                    <Row>
                        <Col>
                            <Label for="payer_uuid__username">이름</Label>
                            <input 
                                name="payer_uuid__username"
                                value={payer_uuid__username}
                                onChange={handleInputChange} 
                                type="string"
                            />
                        </Col>
                        <Col>
                            <Label for="payer_uuid__phone">연락처</Label>
                            <input 
                                name="payer_uuid__phone"
                                value={payer_uuid__phone}
                                onChange={handleInputChange} 
                                type="tel"
                                pattern="[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}"
                            />
                        </Col>
                        <Col>
                            <Label for="name">과정명</Label>
                            <input 
                                name="name"
                                value={bond_name}
                                onChange={handleInputChange} 
                                type="string" 
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label for="bond_policy_bond_tpye">채권유형</Label>
                            <select 
                                name="bond_policy__bond_type"
                                value={bond_policy__bond_type}
                                onChange={handleInputChange}
                            >
                                <option value="null">전체</option>
                                <option value="ISA">ISA</option>
                                <option value="GENERAL">일반(후불제)</option>
                                <option value="PREPAID">일반(선불제)</option>
                            </select>
                        </Col>
                        <Col>
                            <Label for="bondstatus__bond_status">채권상태</Label>
                            <select 
                                name="bondstatus__bond_status"
                                value={bondstatus__bond_status}
                                onChange={handleInputChange}
                            >
                                <option value="null">전체</option>
                                <option value="1">초기화</option>
                                <option value="2">납부 진행중</option>
                                <option value="3">납부 완료</option>
                                <option value="4">납부 연체</option>
                                <option value="5">납부 완료(연체)</option>
                                <option value="6">납부 연체(추심)</option>
                                <option value="7">강제 종료</option>
                                <option value="8">무효</option>
                                <option value="9">포기</option>
                                <option value="11">미납</option>
                                <option value="10">알 수 없음</option>
                            </select>
                        </Col>
                        <Col>
                            <Label for="bond_policy__deposit_policy__type">보증금유형</Label>
                            <select 
                                name="bond_policy__deposit_policy__type"
                                value={bond_policy__deposit_policy__type}
                                onChange={handleInputChange}
                            >
                                <option value="null">전체</option>
                                <option value="GENERAL">일반</option>
                                <option value="INCLUDE_1ST_PAYMENT">ISA</option>
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label for="bondStartDate">채권시작일</Label>
                            <DatePicker
                                name="bond_policy__bond_start_date_lowerbound"
                                value={bond_policy__bond_start_date_lowerbound}
                                clearIcon={null}
                                onChange={(date) => {setBondDateStartLowerBound(date); renderDayContents(date, 'bond_policy__bond_start_date_lowerbound'); }}
                            />
                            <span>~</span>
                            <DatePicker
                                name="bond_policy__bond_start_date_upperbound"
                                value={bond_policy__bond_start_date_upperbound}
                                clearIcon={null}
                                onChange={(date) => {setBondDateStartUpperBound(date); renderDayContents(date, 'bond_policy__bond_start_date_upperbound'); }}
                            />
                        </Col>
                        <Col>
                            <Label for="bondEndDate">채권종료일</Label>
                            <DatePicker
                                name="bond_policy__bond_end_date_lowerbound"
                                value={bond_policy__bond_end_date_lowerbound}
                                clearIcon={null}
                                onChange={(date) => {setBondDateEndLowerBound(date); renderDayContents(date, 'bond_policy_bond_end_date_lowerbound'); }}
                            />
                            <span>~</span>
                            <DatePicker
                                name="bond_policy__bond_end_date_upperbound"
                                value={bond_policy__bond_end_date_upperbound}
                                clearIcon={null}
                                onChange={(date) => {setBondDateEndUpperBound(date); renderDayContents(date, 'bond_policy__bond_end_date_upperbound'); }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label for="paymentEndDate">등록일</Label>
                            <DatePicker
                                name="created_time_lowerbound"
                                value={created_time_lowerbound}
                                clearIcon={null}
                                onChange={(date) => {setCreatedTimeLowerBound(date); renderDayContents(date, 'created_time_lowerbound'); }}
                            />
                            <span>~</span>
                            <DatePicker
                                name="created_time_upperbound"
                                value={created_time_upperbound}
                                clearIcon={null}
                                onChange={(date) => {setCreatedTimeUpperBound(date); renderDayContents(date, 'created_time_upperbound'); }}
                            />
                            <Button onClick={handleDate} value={7} size="sm" className="date-button" >1주일</Button>
                            <Button onClick={handleDate} value={30} size="sm" className="date-button" >1개월</Button>
                            <Button onClick={handleDate} value={60} size="sm" className="date-button" >2개월</Button>
                        </Col>
                    </Row>

                    <Row>
                        <div className="search-button-wrapper">
                            <Button color="success" className="search-submit" type="submit">검색하기</Button>
                            <Button color="white" className="search-reset" type="reset">초기화</Button>
                        </div>
                    </Row>
                </Form>
            </div>
        </div>
    )
};

export default withRouter(BondSearch);
