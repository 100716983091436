import React, { Fragment } from 'react';
import LogOut from '../LoginPage/logout';

export default function Header () {

    return (
        <Fragment> 
            <header className="header-wrap">
                <div className="header-title">
                    <div className="header-inner">
                    </div>
                </div>
                <LogOut /> 
            </header>
        </Fragment>
    )
}
