import * as loginAPI from '../api/loginAPI';
import { 
    LOGIN_USER, 
    LOGIN_SUCCESS, 
    LOGIN_ERROR,
    LOGGED_OUT,
    AUTH_CHECK,
    AUTH_CHECK_SUCCESS,
    AUTH_CHECK_ERROR
} from './_types';


//로그인 관련 action 생성자 함수
export const postLogin = (userData) => async dispatch => {
    dispatch({ type: LOGIN_USER }); // 요청 시작
    try {
        const data = await loginAPI.postLogin(userData); // API 호출
        dispatch({ type: LOGIN_SUCCESS, data }); // 요청 성공
        localStorage.setItem('Token', data.key);
        window.location.href = '/board';
    } catch (e) {
        dispatch({ type: LOGIN_ERROR, error: e });
    }
};

//로그아웃 관련 action 생성자 함수
export const postLogout = () => async dispatch => {
    const data = await loginAPI.postLogout();
    dispatch({ type: LOGGED_OUT, data });
    localStorage.removeItem('Token');
    window.location.href = '/';
};

//회원정보 대조 관련 action 생성자 함수
export const authCheck = () => async dispatch => {
    dispatch({ type: AUTH_CHECK });
    try{
        const data = await loginAPI.authCheck();
        dispatch({ type: AUTH_CHECK_SUCCESS, data });
    } catch (e) {
        dispatch({ type: AUTH_CHECK_ERROR, error: e });
        localStorage.removeItem('Token');
    }
};