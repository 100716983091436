import { Button } from 'reactstrap';
import { ExcelFile, ExcelSheet, ExcelColumn } from '../../../components/parts/excelExports';

export const TableThread = () => {
    return(
        <thead>
            <tr align="center">
                <td></td>
                <td>등록일</td> 
                <td>이름</td>
                <td>연락처</td> 
                <td>이메일</td>
                <td>성별</td>
                <td>생년월일</td>
                <td>주소</td>
            </tr> 
        </thead>
    )
};

export const TableExport = (props) => {
    return (
        <ExcelFile element={<Button color="success" style={{marginBottom:'30px'}}>Export Excel</Button>}>
            <ExcelSheet data={props.data} name="급여 리스트">
                <ExcelColumn label="이름" value="name"/>
                <ExcelColumn label="연락처" value="phone"/>
                <ExcelColumn label="이메일" value="email" />
                <ExcelColumn label="성별" value="gender" />
                <ExcelColumn label="생년월일" value="birthDay" />
                <ExcelColumn label="주소" value="address" />
            </ExcelSheet>
        </ExcelFile>
    );
};
