import * as employmentAPI from '../api/employmentAPI';
import {
    GET_EMPLOYMENTS_LIST,
    GET_EMPLOYMENTS_LIST_SUCCESS,
    GET_EMPLOYMENTS_LIST_ERROR,
} from './_types';


export const getEmploymentParts = (offset, pageLimit, currentPage, PageChunk, props) => async dispatch => {
    dispatch({ type: GET_EMPLOYMENTS_LIST });
    try {
        const data = await employmentAPI.getEmploymentPart(offset, pageLimit, currentPage, PageChunk);

        if (data[0].results.length > 0) {
            dispatch({ type: GET_EMPLOYMENTS_LIST_SUCCESS, data }); // 요청 성공
        } else {
            alert('존재하지 않는 페이지입니다.');
            const data = await employmentAPI.getEmploymentPart(0, pageLimit, 1, 0); // API 재호출
            dispatch({ type: GET_EMPLOYMENTS_LIST_SUCCESS, data }); // 재요청 성공
            props.history.push(`/${props.page}?page=1`); // 1페이지로 redirect
        };

    } catch(e) {
        dispatch({ type: GET_EMPLOYMENTS_LIST_ERROR, error: e });
    };
};
