import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../parts/Header';
import Sidebar from '../parts/Sidebar';
import SideHeader from '../parts/SideHeader';
import PaymentSearch from '../../_components/search/searchParts/paymentSearch';
import SearchList from '../../_components/search/searchList';
import Paginations from '../parts/Paginations';
import { FooterBoard } from '../parts/Footer';
import PaymentList from '../../_components/payment/PaymentList';
import { getPayments } from '../../_actions/payment_actions';
import { getPaymentSearch } from '../../_actions/search_action';

const PaymentPage = (props) => {

    const PAYMENT = 'payment';
    const { mode } = useSelector(state => state.modes);
    const { count } = useSelector(state => state.payment.payments);
    const { payments } = useSelector(state => state.payment);
    const paymentCurrentPage = useSelector(state => state.payment.payments.currentPage);
    const paymentCurrentPageChunk = useSelector(state => state.payment.payments.pageChunk);

    const searchCount = useSelector(state => state.searches.paymentSearches.count);
    const searchQuery = useSelector(state => state.searches.paymentSearches.query);
    const searchCurrentPage = useSelector(state => state.searches.paymentSearches.currentPage);
    const searchCurrentPageChunk = useSelector(state => state.searches.paymentSearches.pageChunk);

    let contents;
   
    //URLSearchParams로 react-router의 query String을 object화
    let search = new URLSearchParams(props.location.search);

    //get 메소드로 해당 key 값 조회 (?page=1 일 시 page가 key가 되고 1이 value)
    let pageNum = parseInt(search.get('page')) || 1;

    if (mode === 'read') {
        contents = <div className="main-content">
            <SideHeader big="PAYMENT" small="청구서 목록" />
            <PaymentSearch />
            <PaymentList data={payments} />
            <Paginations  
                page={PAYMENT} 
                count={count} 
                pageNum={pageNum}
                currentPage={paymentCurrentPage} 
                pageChunk={paymentCurrentPageChunk}
                dispatch={getPayments}
            />
        </div>
    } else if (mode === 'search') {
        contents = <div className="main-content">
            <SideHeader big="PAYMENT" small="청구서 검색 결과"/>
            <PaymentSearch />
            <SearchList page={PAYMENT}/>
            <Paginations 
                page={PAYMENT} 
                pageNum={pageNum}
                count={searchCount} 
                query={searchQuery} 
                currentPage={searchCurrentPage} 
                pageChunk={searchCurrentPageChunk}
                dispatch={getPaymentSearch}
            />
        </div>
    };


    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            {contents}
            <FooterBoard />
        </div>
    );

};

export default withRouter(PaymentPage);