import React from 'react';

//데이터 로딩 시 보여주는 화면. css로 작업.
const Loading = () => {
    return (
        <>
        <div className="loading-wrapper">
            <div className="loadingio-spinner-bean-eater-ilfegg7bdpn">
                <div className="ldio-9iqcqxp9ou7">
                    <div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
        </>

    )
}

export default Loading;
