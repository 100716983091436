import Axios from 'axios';
import { URL_PRESENT } from './URL';
import { headerConfig } from './cookie/common';

//개별 채권의 청구서 정보를 전부 들고 오는 api
export const getStatus = async (uuid) => {
    const response = await Axios.get(
        `${URL_PRESENT}/payment/bond/${uuid}/scheduled-payment/`, 
        headerConfig()
    );
    return response.data.results;
};

//개별 청구서의 상태를 변경하는 api
export const postScheduledPaymentStatus = async (scheduledPaymentUuid, statusData) => {
    const response = await Axios.post(
        `${URL_PRESENT}/payment/scheduled-payment/${scheduledPaymentUuid}/status/`, 
        statusData, 
        headerConfig()
    );
    return response;
};