import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BondTable from './BondTable';
import UpdateTable from '../update/UpdateTable';
import LoadingContent from '../common/LoadingContent';

const BondsList = (props) => {

    const { mode } = useSelector(state => state.modes);
    let { loading, data, error } = props.data;
    let contents = null;

    if(loading === 'loading...' && !data) {
        contents = <LoadingContent />
    } else if (loading === 'loading success...' && data && mode === 'read') {
        contents = <>
            <BondTable data={data} />
        </>
    } else if (loading === 'loading success...' && data && mode === 'update') {
        //현재 안 쓰임
        contents = <>
            <UpdateTable data={data} />
        </>
    } else if(error) {
        contents = <div className="main-content">
            <div className="null-wrapper">
                <div className="null"></div>
                <p>Error!</p>
            </div>
        </div>
    } else if (!data) {
        contents = <div className="main-content">
            <div className="null-wrapper">
                <div className="null"></div>
                <p>Data is Null</p>
            </div>
        </div>
    };

    return (
        <>  
            {contents}
        </>
    );

};

export default withRouter(BondsList);