import { 
    GET_INCOME_LIST,
    GET_INCOME_LIST_SUCCESS,
    GET_INCOME_LIST_ERROR,
} from '../_actions/_types';

//초깃값
const initialState = {
    incomes : {
        loading : null,
        count: null,
        data: [],
        currentPage: 1,
        pageChunk: 0,
        error: null,
    }
}

//급여 리듀서
export default function incomeReducer(state = initialState, action) {
    switch (action.type) {
        case GET_INCOME_LIST:
            return {
                ...state,
                incomes: {
                    loading: 'loading...',
                    count: null,
                    data: null,
                    error: null,
                }
            };
        case GET_INCOME_LIST_SUCCESS:
            return {
                ...state,
                incomes: {
                    loading: 'loading success...',
                    count: action.data[0].count,
                    data: action.data[0].results,
                    currentPage: action.data[1],
                    pageChunk: action.data[2],
                    error: null,
                }
            };
        case GET_INCOME_LIST_ERROR:
            return {
                ...state,
                incomes: {
                    loading: 'loading error...',
                    count: null,
                    data: null,
                    error: action.error,
                }
            };
        default:
            return state;
    }
};

