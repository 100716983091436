import { 
    LOGIN_USER, 
    LOGIN_SUCCESS, 
    LOGIN_ERROR,
    LOGGED_OUT,
    AUTH_CHECK,
    AUTH_CHECK_SUCCESS,
    AUTH_CHECK_ERROR,
} from '../_actions/_types';

//초깃값
const initialState = {
    data : {
        loading : null,
        data: null,
        isLoggedIn: false,
        auth_check: null,
        error: null,
    }
}

//로그인/로그아웃/회원 대조 리듀서
export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                data: {
                    loading: true,
                    data: null,
                    isLoggedIn: false,
                    auth_check: null,
                    error: null,
                }
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                data: {
                    loading: true,
                    data: action.data,
                    isLoggedIn: true,
                    auth_check: null,
                    error: null,
                }
            };
        case LOGIN_ERROR:
            return {
                ...state,
                data: {
                    loading: true,
                    data: null,
                    isLoggedIn: false,
                    auth_check: null,
                    error: action.error,
                }
            };
        case AUTH_CHECK:
            return {
                ...state,
                data: {
                    loading: true,
                    data: null,
                    isLoggedIn: null,
                    auth_check: null,
                    error: null,
                }
            };
        case AUTH_CHECK_SUCCESS:
            return {
                ...state,
                data : {
                    loading: true,
                    data: action.data,
                    isLoggedIn: true,
                    auth_check : true,
                    error: null,
                }
            };
        case AUTH_CHECK_ERROR:
            return {
                ...state,
                data : {
                    loading: false,
                    data: null,
                    isLoggedIn: null,
                    auth_check: false,
                    error: action.error,
                }
            }
        case LOGGED_OUT:
            return {
                ...state,
                data: {
                    loading: true,
                    data: null,
                    isLoggedIn: false,
                    auth_check: false,
                    error: null,
                }
            }
        default:
            return state;
    }
};