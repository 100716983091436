import React, {useState} from 'react';
import { Button, Form, Col, Row, Label } from 'reactstrap';
import DatePicker from 'react-date-picker';
import { removeHypen, pageSlice, pageLimit, daysIntervalCal, autoHyphenDate } from '../../common/common';
import { useDispatch } from 'react-redux';
import { getPaymentSearch } from '../../../_actions/search_action';
import { initialState } from '../../../_reducers/search_reducer';
import { getMode } from '../../../_actions/mode_action';
import { withRouter } from 'react-router';

const PaymentSearch = (props) => {

    const dispatch = useDispatch();
    const [isActive, setIsActive] = useState(true);
    const [side, setSide] = useState(false);
    const [bond_uuid__payer_uuid__username, setBondPayerUsername] = useState('');
    const [bond_uuid__payer_uuid__phone, setBondPayerPhone] = useState('');
    const [scheduled_payment_type, setScheduledPaymentType] = useState('');
    const [scheduled_payment_status, setScheduledPaymentStatus] = useState('');
    const [bond_uuid__name, setBondName] = useState('');
    const [payment_due_date_lowerbound, setPaymentDueLowerBound] = useState('');
    const [payment_due_date_upperbound, setPaymentDueUpperBound] = useState('');
    const [payment_date_lowerbound, setPaymentDateLowerBound] = useState('');
    const [payment_date_upperbound, setPayementDateUpperBound] = useState('');
    const [created_time_lowerbound, setCreatedTimeLowerbound] = useState('');
    const [created_time_upperbound, setCreatedTimeUpperbound] = useState('');
    const { currentPage, pageChunk } = initialState.paymentSearches;

    const [query_array, setQuerayArray] = useState({
        bond_uuid__payer_uuid__username: '',
        bond_uuid__payer_uuid__phone: '',
        scheduled_payment_type: '',
        scheduled_payment_status: '',
        bond_uuid__name: '',
        payment_due_date_lowerbound: '',
        payment_due_date_upperbound: '',
        payment_date_lowerbound: '',
        payment_date_upperbound: '',
        created_time_lowerbound: '',
        created_time_upperbound: '',
    });

    const handleActive = () => {
        setIsActive(!isActive);
        setSide(!side);
    };

    const handleInputChange = (e) => {
        e.preventDefault();
        let { name, value } = e.target;

        if (name === 'bond_uuid__payer_uuid__username') {
            setBondPayerUsername(value);
        } else if (name === 'bond_uuid__payer_uuid__phone') {
            setBondPayerPhone(removeHypen(value));
        } else if (name === 'scheduled_payment_type') {
            setScheduledPaymentType(value);
        } else if (name === 'scheduled_payment_status') {
            setScheduledPaymentStatus(value);
        } else if (name === 'bond_uuid__name') {
            setBondName(value);
        }

        setQuerayArray({
            ...query_array,
            [name] : value
        });
    };

    const querySet = (value1, value2, func1, func2 ) => {
        setQuerayArray({
            ...query_array,
            [value1]: `${autoHyphenDate(func1)}T00:00:00`,
            [value2]: `${autoHyphenDate(func2)}T23:59:59`,
        });
    }

    const querySet2 = (name,year,month,date,value ) => {
        if(value === null){
            setQuerayArray({
                ...query_array,
                [name] : `${year}-${month}-${date}`
            });
        }
    }

    const renderDayContents = (day, name) => {
        let year = new Date(day).getFullYear();
        let month = new Date(day).getMonth() + 1;
        let date = new Date(day).getDate();

        if(name.indexOf('_lowerbound') !== -1){
            querySet2(name, year, month, date, null);
        } else if(name.indexOf('_upperbound') !== -1) {
            querySet2(name, year, month, date, null);
        }
    };

    const handleDate = (e) => {
        e.preventDefault();
        let { value } = e.target;
        setCreatedTimeUpperbound(new Date());
        setCreatedTimeLowerbound(daysIntervalCal(value));
        querySet('created_time_lowerbound', 'created_time_upperbound', daysIntervalCal(value), daysIntervalCal(0));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const arr = [];
        for(let key in query_array){
            if(query_array[key]){
                if(key === 'bond_uuid__payer_uuid__phone') {
                    arr.push(`${key}=${removeHypen(query_array[key])}`);
                } else if (key.indexOf('_type') !== -1 || key.indexOf('_status') !== -1) {
                    if(query_array[key] !== 'null') {
                        arr.push(`${key}=${query_array[key]}`);
                    }
                } else {
                    arr.push(`${key}=${query_array[key]}`);
                }
            }
        };
        dispatch(getPaymentSearch(arr.join('&'), pageSlice, pageLimit, currentPage, pageChunk));
        dispatch(getMode('search'));   
        props.history.push(`/payment?page=1`);
    };

    const handleReset = (e) => {
        e.preventDefault();
        let { name } = e.target;
        
        setBondPayerUsername('');
        setBondPayerPhone('');
        setScheduledPaymentType('');
        setScheduledPaymentStatus('');
        setBondName('');
        setPaymentDueLowerBound('');
        setPaymentDueUpperBound('');
        setPaymentDateLowerBound('');
        setPayementDateUpperBound('');
        setCreatedTimeLowerbound('');
        setCreatedTimeUpperbound('');      

        setQuerayArray({
            [name] : '',
        })
    }

    return (
        <div className="search-box">
            <div style={{display:'grid'}}>
                <div className="search-sub">
                    <h3 className="search-title">
                        <div onClick={handleActive} className={`arrow ${side ? "up" : "down"}`}></div>
                    </h3>
                    <hr className="search-hr"/>
                </div>
                <Form 
                    className={`form-table ${isActive ? "active" : "notActive"}`} 
                    onSubmit={handleSubmit}
                    onReset={handleReset}
                >
                    <Row>
                        <Col>
                            <Label for="bondPayerUsername">이름</Label>
                            <input 
                                name="bond_uuid__payer_uuid__username"
                                value={bond_uuid__payer_uuid__username}
                                onChange={handleInputChange} 
                                type="string" 
                            />
                        </Col>
                        <Col>
                            <Label for="bondPayerPhone">연락처</Label>
                            <input 
                                name="bond_uuid__payer_uuid__phone"
                                value={bond_uuid__payer_uuid__phone}
                                onChange={handleInputChange} 
                                type="tel"
                                pattern="[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}"
                            />
                        </Col>
                        <Col>
                            <Label for="paymentBondName">과정명</Label>
                            <input 
                                name="bond_uuid__name"
                                value={bond_uuid__name}
                                onChange={handleInputChange}
                                type="string"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label for="scheduledPaymentType">납부유형</Label>
                            <select 
                                name="scheduled_payment_type"
                                value={scheduled_payment_type}
                                onChange={handleInputChange}
                            >
                                <option value="null">전체</option>
                                <option value="1">일반납부</option>
                                <option value="2">선납</option>
                                <option value="3">최대금액납부</option>
                                <option value="4">보증금</option>
                                <option value="5">서비스이용료</option>
                                <option value="6">기타</option>
                            </select>
                        </Col>
                        <Col>
                            <Label for="scheduledPaymentStatus">납부상태</Label>
                            <select 
                                name="scheduled_payment_status"
                                value={scheduled_payment_status}
                                onChange={handleInputChange}
                            >
                                <option value="null">전체</option>
                                <option value="1">초기화</option>
                                <option value="2">납부 진행중</option>
                                <option value="3">납부 완료</option>
                                <option value="4">납부 연체</option>
                                <option value="5">납부 완료(연체)</option>
                                <option value="6">납부 연체(추심)</option>
                                <option value="7">강제 종료</option>
                                <option value="8">무효</option>
                                <option value="9">포기</option>
                                <option value="11">미납</option>
                                <option value="10">알 수 없음</option>
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label for="incomeDate">납부기한</Label>
                            <DatePicker
                                name="payment_due_date_lowerbound"
                                value={payment_due_date_lowerbound}
                                clearIcon={null}
                                onChange={(date) => {setPaymentDueLowerBound(date); renderDayContents(date, 'payment_due_date_lowerbound'); }}
                            />
                            <span> ~ </span>
                            <DatePicker
                                name="payment_due_date_upperbound"
                                value={payment_due_date_upperbound}
                                clearIcon={null}
                                onChange={(date) => {setPaymentDueUpperBound(date); renderDayContents(date, 'payment_due_date_upperbound'); }}
                            />
                        </Col>
                        <Col>
                            <Label for="incomeDate">납부일</Label>
                            <DatePicker
                                name="payment_date_lowerbound"
                                value={payment_date_lowerbound}
                                clearIcon={null}
                                onChange={(date) => {setPaymentDateLowerBound(date); renderDayContents(date, 'payment_date_lowerbound'); }}
                            />
                            <span> ~ </span>
                            <DatePicker
                                name="payment_date_upperbound"
                                value={payment_date_upperbound}
                                clearIcon={null}
                                onChange={(date) => {setPayementDateUpperBound(date); renderDayContents(date, 'payment_date_upperbound'); }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label for="created-time">등록일</Label>
                            <DatePicker
                                name="created_time_lowerbound"
                                value={created_time_lowerbound}
                                clearIcon={null}
                                onChange={date => {setCreatedTimeLowerbound(date); renderDayContents(date, 'created_time_lowerbound'); }}
                            />
                            <span> ~ </span>
                            <DatePicker
                                name="created_time_upperbound"
                                value={created_time_upperbound}
                                clearIcon={null}
                                onChange={date => {setCreatedTimeUpperbound(date); renderDayContents(date, 'created_time_upperbound'); }}
                            />
                            <Button size="sm" onClick={handleDate} value={7} className="date-button" >1주일</Button>
                            <Button size="sm" onClick={handleDate} value={30} className="date-button" >1개월</Button>
                            <Button size="sm" onClick={handleDate} value={60} className="date-button" >2개월</Button>
                        </Col>
                    </Row>
                    <Row>
                        <div className="search-button-wrapper">
                            <Button color="success" className="search-submit" type="submit">검색하기</Button>
                            <Button color="white" className="search-reset" type="reset">초기화</Button>
                        </div>
                    </Row>
                </Form>
            </div>
        </div>
    )
};

export default withRouter(PaymentSearch);
