import React, { Fragment } from 'react';


export default function Footer () {
    return (
        <Fragment> 
            <footer className="footer-wrap">
                <div className="footer-title">
                    <div className="footer-inner">학생독립만세</div>
                </div>
            </footer>
        </Fragment>
    )
}

export function FooterInfo () {
    return (
        <Fragment> 
            <footer className="footer-wrap herit">
                <div className="footer-title">
                    <div className="footer-inner">학생독립만세</div>
                </div>
            </footer>
        </Fragment>
    )
}

export function FooterBoard () {
    return (
        <Fragment> 
            <footer className="footer-wrap herit">
                <div className="footer-title">
                    <div className="footer-inner">학생독립만세</div>
                </div>
            </footer>
        </Fragment>
    )
}
