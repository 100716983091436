
import { Button } from 'reactstrap';
import { ExcelFile, ExcelSheet, ExcelColumn } from '../../../components/parts/excelExports';
import { autoHyphenDate } from '../../common/common';
import { sortBy } from 'lodash';

//근로 상태 출력 함수
export const userEmploy = (employmentStatus) => {

    if(employmentStatus.length >= 1) {
        let isRecent = employmentStatus.filter(function (element) { return element.is_recent === true });
        if(isRecent) {
            let { employment_status } = isRecent[0]
            switch (employment_status) {
                case 1:
                    return '초기';
                case 2:
                    return '알 수 없음';
                case 3:
                    return '삭제됨';
                case 4:
                    return '취업';
                case 5:
                    return '퇴사';
                case 6:
                    return '미취업';
                default:
                    break;
            }
        }
    };

    let { employment_status } = employmentStatus;
    switch (employment_status) {
        case 1:
            return '초기';
        case 2:
            return '알 수 없음';
        case 3:
            return '삭제됨';
        case 4:
            return '취업';
        case 5:
            return '퇴사';
        case 6:
            return '미취업';
        default:
            break;
    }

};

//employment_status 상태에 따라서 -valid_from을 기준으로 sortBy 해주는 함수
const sortByFunc = (Status, number) => {
    return sortBy(Status.filter(function (element){ return element.employment_status === number }), 'valid_from').reverse();
};

//입사일/퇴사일 출력 함수, 최신 상태는 필터링할 필요 없다.
export const validFromTo = (employmentStatus, validArg) => {
    //validArg가 validFrom일 때
   if(validArg === 'validFrom') {
        //employment_status가 4인 가장 마지막 상태의 valid_from를 출력
        return autoHyphenDate(sortByFunc(employmentStatus, 4)[0]?.valid_from);
    //validArg가 validTo일 때
   } else {
       return autoHyphenDate(sortByFunc(employmentStatus, 5)[0]?.valid_from);
   }
};

//퇴사 이유 출력 함수
export const leaveReason = (employmentStatus) => {

    if(employmentStatus.length >= 1){
        let isRecent = employmentStatus.filter(function (element) { return element.is_recent === true});
    
        if(isRecent && isRecent[0].employment_status === 5) {
            let leaveReason = isRecent[0].employment_leave_reason;
            switch (leaveReason) {
                case 1:
                    return '재취업준비';
                case 2:
                    return '개인사유';
                case 3:
                    return '회사사유';
                case 9:
                    return '진로변경';
                case 99:
                    return '불가항력사유';
                case 999:
                    return isRecent[0].employment_other_status;
                default:
                    break;
            }
        }
    }

    let leaveReason = employmentStatus.employment_leave_reason;
    if(leaveReason) {
        switch (leaveReason) {
            case 1:
                return '재취업준비';
            case 2:
                return '개인사유';
            case 3:
                return '회사사유';
            case 9:
                return '진로변경';
            case 99:
                return '불가항력사유';
            case 999:
                return employmentStatus.employment_other_status;
            default:
                break;
        }
    }
    return '';

};

//table header component
export const TableThread = () => {
    return(
        <thead>
            <tr align="center">
                <td></td>
                <td>등록일</td>
                <td>이름</td>
                <td>연락처</td>
                <td>과정명</td> 
                <td>취업상태</td>
                <td>취업처</td>
                <td>급여일</td>
                <td>연소득</td>
                <td>입사일</td>
                <td>퇴사일</td>
                <td>퇴사사유</td>
                <td>근로계약서</td>
            </tr> 
        </thead>
    )
};

//excel export component
export const TableExport = ({data}) => {
    
    return (
        <ExcelFile element={<Button color="success" style={{marginBottom:'30px'}}>Export Excel</Button>}>
            <ExcelSheet data={data} name="취업 리스트">
                <ExcelColumn label="등록일" value="createdTime" />
                <ExcelColumn label="이름" value="name"/>
                <ExcelColumn label="연락처" value="phone"/>
                <ExcelColumn label="과정명" value="course" />
                <ExcelColumn label="취업상태" value="employmentStatus" />
                <ExcelColumn label="취업처" value="companyName" />
                <ExcelColumn label="급여일" value="incomeDate" />
                <ExcelColumn label="연소득" value="annualIncome" />
                <ExcelColumn label="입사일" value="hireDate" />
                <ExcelColumn label="퇴사일" value="resignDate" />
                <ExcelColumn label="퇴사사유" value="leaveReason" />
            </ExcelSheet>
        </ExcelFile>
    );
};
