import React from 'react';

//중복되는 부분을 컴포넌트화
const TypeComponent = (props) => {
    if(props.function){
        return (
            <div className="type-component">
                <label>{props.name}</label>
                <p>{props.function}</p>
            </div>
        );
    } else {
        return <></>;
    };
};

//연체 이자율 출력 함수
export const TypeInterestsComponent = (props) => {
    if(props.function){
        let contents = props.function.map((data, index) => {
            let roman = ['I', 'II', 'III'];
            return <div className="type-component" key={index}>
                <label>연체 이자율{roman[index]}(%)</label>
                <p>{data.rate * 100}</p>
            </div>
        });
        return (
            <>{contents}</>
        )
    }

    return (
        <></>
    )
};

export default TypeComponent;