import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getPdfLoad, getFileLoad, getIncomeS3URL, getEmploymentS3URL } from '../../api/fileAPI';
import { removeExtension, ImageViewer } from './common';
import { AiOutlineFilePdf, AiOutlineFileZip, AiOutlineFileJpg, AiOutlineFileImage } from 'react-icons/ai';
import { Carousel } from 'react-responsive-carousel';

const ImageViewerModal = (props) => {

    const [modal, setModal] = useState(false);
    const [s3ArrURL, setS3ArrURL] = useState([]);
    let links;
    let content;

    //페이지 별로 쓸 document api를 object 형식으로 담아둠
    let pageURLInfo = {
      "income" : getIncomeS3URL,
      "employment" : getEmploymentS3URL
    };

    //파일 별 호출 api, icon, type 등을 object 형식으로 담아둠
    let fileInfo = {
        "zip" : {
            icon: <AiOutlineFileZip className="file-fa" />,
            type: "zip",
            api: getFileLoad
        },
        "pdf" : {
            icon: <AiOutlineFilePdf className="file-fa" />,
            type: "image/pdf",
            api: getPdfLoad
        },
        "png" :  {
            icon: <AiOutlineFileImage className="file-fa" />,
            type: "image/png",
            api: getFileLoad
        },
        "jfif" : {
            icon: <AiOutlineFileJpg className="file-fa" />,
            type: "application/octet-stream",
            api: getFileLoad
        },
        "jpeg" : {
            icon: <AiOutlineFileJpg className="file-fa" />,
            type: "image/jpeg",
            api: getFileLoad
        },
        "jpg" : {
            icon: <AiOutlineFileJpg className="file-fa" />,
            type: "image/jpeg",
            api: getFileLoad
        }
    };

    const toggle = () => setModal(!modal);
  
    const getS3Link = async () => {
        const S3LinkArr = [];
        //pageURLInfo object 에서 page 별로 가져올 api를 가져와 호출
        const resData = await pageURLInfo[props.page](props.uuid);
        
        if (resData) {

            if (resData.message) {

                //api 호출 이후 message가 존재할 시
                S3LinkArr.push({url: '', title : resData.message, extension: ''});
            };

            for(let i = 0; i < resData.length; i++) {

                let { title } = resData[i];
                let fileExtension = title.split('.')[1];

                //개발계에서 쓰일 데이터를 따로 정의한다.
                let resDataUrl = resData[i].s3_url.split(`https://s3.ap-northeast-2.amazonaws.com`)[1];
                let fixedDevData = {
                    s3_url: resDataUrl,
                    title: resData[i].title,
                    encrypted_key_b64: resData[i].encrypted_key_b64,
                    encrypted_key_md5: resData[i].encrypted_key_md5,
                };
    
                if(title.indexOf(fileExtension) !== -1){
                    
                    //resDataURL은 들어온 확장자를 fileInfo object에서 찾는다. 
                    //환경이 production일 땐 변형하지 않은 데이터를 넣고, development일 땐 변형한 데이터를 넣는다.
                    let resDataURL = await fileInfo[fileExtension].api(
                        (process.env.NODE_ENV === 'production') ? 
                        resData[i] : 
                        fixedDevData
                    );
                    
                    if (resDataURL) {

                        //미리 선언해둔 배열에 해당 요소들을 객체화 시켜 담는다.
                        S3LinkArr.push({url: resDataURL, title : title, extension: fileExtension});
                    };

                } else {

                    //지원하지 않는 확장자 타입일 경우 alert
                    alert('지원하지 않는 확장자 타입입니다.');
                };
            };
            setS3ArrURL(S3LinkArr);
        };
    };

    if(s3ArrURL){

        for(let i = 0; i < s3ArrURL.length; i++) {

            let { title } = s3ArrURL[i];
            let { extension } = s3ArrURL[i];

            if(title.indexOf(extension) !== -1) {

                //extension이 있을 시와 없을 시를 case 별로 분기
                switch (extension) {
                    case extension:
                        content = <ImageViewer arr={s3ArrURL} Carousel={Carousel} />;
                        //다운로드 링크에 들어할 내용을 map 함수로 하나씩 꺼내 출력한다.
                        links = s3ArrURL.map(data => {
                            return (
                                <li key={data.url} className="link-li">
                                    <p>{removeExtension(data.title)}
                                        {/* a 태그에 들어갈 내용과 아이콘 등을 fileInfo 객체 내에서 꺼내와 넣는다.  
                                            download의 경우 .jfif 확장자인 경우 삼항 연산자를 써 뒤에 .jpg 확장자를 붙인다.
                                        */}
                                        <a 
                                            href={data.url} 
                                            type={fileInfo[data.extension]?.type} 
                                            download={
                                                (data.title.includes('jfif')) ? 
                                                `${removeExtension(data.title)}.jpg` : 
                                                data.title
                                            }
                                        >
                                            {fileInfo[data.extension]?.icon}
                                        </a>
                                    </p>
                                </li>
                            );
                        });
                        break;
                    case '':
                        content = <p style={{textAlign:"center"}}>문서가 존재하지 않습니다.</p>;
                        links = null;
                        break;
                    default:
                        break;
                };
            };
        };
    };

    //최종 렌더링
    return (
        <div>
            <Button color="success" size="sm" onClick={() => {toggle(); getS3Link();}}>보기</Button>
            <Modal isOpen={modal} fade={false} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    { (props.page==='income') ? "급여명세서" : 
                        (props.page === 'employment') ? "근로계약서" : "문서 뷰어" }
                </ModalHeader>
                <ModalBody>
                {content}
                </ModalBody>
                <ModalFooter>
                <h5>다운로드</h5>
                <ul>
                    {links}
                </ul>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ImageViewerModal;