import { combineReducers } from 'redux';
import users from './user_reducer';
import bonds from './bond_reducer';
import modes from './mode_reducer';
import searches from './search_reducer';
import status from './status_reducer';
import employment from './employment_reducer';
import income from './income_reducer';
import payment from './payment_reducer';
import member from './member_reducer';

const rootReducer = combineReducers({
    users,
    bonds,
    modes,
    searches,
    status,
    employment,
    income,
    payment,
    member,
})

export default rootReducer;