import React, { useState } from 'react';
import { AiFillCheckCircle } from "react-icons/ai";
import { useDispatch } from 'react-redux';
import { postScheduledPaymentStatus } from '../../../api/statusAPI';
import { getMode } from '../../../_actions/mode_action';
import { getStatuses } from '../../../_actions/status_action';

const ModifyScpStatus = (props) => {

    const [scpStatus, setScpStatus] = useState(props.scpStatus);

    //select의 option의 value 값과 string 값을 object화 시킨다.
    const scpStatusArr ={
        1: "납부 전",
        2: "납부 진행",
        3: "납부 완료",
        4: "납부 연체",
        5: "납부 완료(연체)",
        6: "납부 연체(추심)",
        7: "강제 종료",
        8: "무효",
        9: "포기",
        11: "미납",
    };

    let options = [];

    const dispatch = useDispatch();

    const handleOnchange = (e) => {
        e.preventDefault();
        const {value} = e.target;
        setScpStatus(parseInt(value));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const postStatusData = { "scheduled_payment_status" : scpStatus };
        const scpModifyConfirm = window.confirm(`${props.paymentTurn}회차 청구서 상태를 ${scpStatusArr[scpStatus]}(으)로 변경하시겠습니까?`);

        if (scpModifyConfirm) {
            return await postScheduledPaymentStatus(props.scpUuid, postStatusData)
                .then((response) => {
                    //정상 처리 시 : http status 201
                    alert('청구서 상태가 변경되었습니다.');
                    dispatch(getStatuses(props.bondUuid));
                    dispatch(getMode('read'));
                })
                .catch((err) => {
                    //error response message 변수에 담기
                    alert(`청구서 상태 변경 중 에러가 발생 했습니다. \n 
                        http code: ${err.response?.status} message : ${err.response?.data?.message}`);
                });
        };
    };

    //scpStatusArr를 map 함수로 돌리면서 props.scpStatus와 scpValue가 같을 때는 disabled 해 렌더링
    for ( let key in scpStatusArr) {
        options.push((parseInt(key) === parseInt(props.scpStatus)) ? 
            <option value={key} key={key} disabled>{scpStatusArr[key]}</option> :
            <option value={key} key={key} >{scpStatusArr[key]}</option>);
    };
    
    return(
        <div className="payment-update-status">
            <div className="payment-update-select">
                <select 
                    onChange={handleOnchange}
                    value={scpStatus}
                    className="modify-status-select"
                >
                    {options}
                </select>
            </div>
            <div className="payment-update-button">
                {(props.scpStatus === scpStatus ?
                    <AiFillCheckCircle className="submit-modify-status-disabled"/> :
                    <AiFillCheckCircle className="submit-modify-status" onClick={handleSubmit} />
                )}
            </div>
        </div>
    );
};

export default ModifyScpStatus;