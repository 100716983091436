import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import MemberTable from './memberTable';
import LoadingContent from '../common/LoadingContent';
import { convertSnakeToCamel } from '../common/common';


const MemberList = (props) => {

    const { loading, data, error } = props.data;
    const { mode } = useSelector(state => state.modes);
    let contents = null;

    if (loading === 'loading...' && !data) {
        contents = <LoadingContent />
    } else if (loading === 'loading success...' && data && mode === 'read') {
        contents = <>
            <MemberTable data={convertSnakeToCamel(data)} />
        </>
    } else if (error) {
        contents = <div className="main-content">
            <div className="null-wrapper">
                <div className="null"></div>
                <p>Error!</p>
            </div>
        </div>;
    } else if (!data) {
        contents = <div className="main-content">
            <div className="null-wrapper">
                <div className="null"></div>
                <p>Data is Null</p>
            </div>
        </div>;
    };

    return (
        <>
            {contents}
        </>
    );
};

export default withRouter(MemberList);