import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, InputGroup, InputGroupAddon, InputGroupText, Label, FormGroup, Fade } from 'reactstrap';
import { deleteIncome } from '../../../api/incomeAPI';
import { getIncomes } from '../../../_actions/income_action';
import bankCode from '../../common/bankCode.json';
import { autoHyphenDate } from '../../common/common';

//급여 정보 삭제 시 환불 여부를 받아 api로 쏘는 함수
const IncomeRefundModal = (props) => {

    const [modal, setModal] = useState(false);
    const [docsSelect, setDocsSelect] = useState(false);
    const [refundCheck, setRefundCheck] = useState(false);
    const [accountOwner, setAccountOwner] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [code, setCode] = useState('');
    let contents = [];
    let mixPattern = /[ㄱ-ㅎ]|[ㅏ-ㅣ]|[가-힣a-zA-Z]/g;

    //JSON 파일을 가져다가 select 내 option 만드는 부분
    contents.push(<option className="option" key={null} value={"null"}>(선택)</option>);

    for (let key in bankCode) {
        contents.push(<option className="option" key={key} value={key}>{bankCode[key]}</option>);
    };

    const dispatch = useDispatch();

    const modalToggle = () => setModal(!modal);

    //은행코드 state에 업데이트 하는 함수
    const handleChangeBankCode = (e) => {
        e.preventDefault();
        setCode(isNaN(e.target.value)? '' : e.target.value);
    };

    //계좌주 state에 업데이트하는 함수
    const handleChangeOwner = (e) => {
        e.preventDefault();
        setAccountOwner(e.target.value);
    };

    //입력 시 숫자만 입력될 수 있게끔 하는 함수
    const onKeyPress = (e) => {
        if (e.charCode < 48 || e.charCode > 57) {
            e.preventDefault();
            return true;
        };
        return false;
    };

    //계좌번호 state에 업데이트하는 함수
    const handleChangeAccount = (e) => {
        e.preventDefault();
        let value = e.target.value.replace(/\-|(\s*)/g,'');

        if (value.match(mixPattern)) {
            //영어나 한글이 섞여있을 시 공백으로 치환
            setAccountNumber(value.replace(mixPattern,''));
        } else {
            setAccountNumber(value);
        };
    };

    //비어있는 정보 체크해서 출력하는 함수
    const emptiedCheck = () => {
        let emptiedArr = [];

        if (code.length === 0) emptiedArr.push('은행코드');
        if (accountNumber.length === 0) emptiedArr.push('계좌번호');
        if (accountOwner.length === 0) emptiedArr.push('계좌주');

        return emptiedArr;
    };

    //환불 api 함수
    const incomeDeleteApi = async (params) => {
        let employmentUuid = props.income.employment.uuid;
        let incomeUuid = props.income.uuid;
        let userName = props.income.employment.party?.username;
        let incomeDate = autoHyphenDate(props.income.income_date).split("-");
        let confirmText = window.confirm(`
        ${userName}님의 ${incomeDate[1]}월 ${incomeDate[2]}일 급여 정보를 정말로 삭제하시겠습니까? \n
        환불 여부 : ${(refundCheck === true) ? "Y" : "N"} / 급여명세서 삭제여부 : ${(docsSelect === true) ? "Y" : "N"}
        `);

        if (confirmText === true) {
            return await deleteIncome(employmentUuid, incomeUuid, params.toString())
                .then((response) => {
                    //api 호출 이후 204(No Content : 데이터 삭제 이후 콘텐츠 없음을 뜻함) 상태
                    alert(`${userName}님의 ${incomeDate[1]}월 ${incomeDate[2]}일 급여 졍보가 성공적으로 삭제 되었습니다.`); 
                    dispatch(getIncomes());
                })
                .catch((err) => {
                    //api 호출 이후 reject 된 상태들
                    alert(`${userName}님의 ${incomeDate[1]}월 ${incomeDate[2]}일 급여 정보 삭제 error \n 
                    http code : ${err.response?.status} message : ${err.response?.data?.message}`);
                });
        };
    };
    
    //제출 시 state를 query string으로 만들어 api 호출 시 보내는 함수
    const handleDeleteIncome = () => {
        const queryParams = new URLSearchParams();

        //계좌주, 계좌번호, 은행코드 전부 존재하는 경우
        if (accountOwner && accountNumber && code) {
            queryParams.append("refund_acct_num", accountNumber);
            queryParams.append("refund_acct_name", accountOwner);
            queryParams.append("refund_bank_code", code);
        };

        //계좌주, 계좌번호, 은행코드가 전부 존재하지 않더라도 환불 여부와 문서 삭제 여부는 param에 담는다.
        queryParams.append("with_refund", refundCheck);
        queryParams.append("with_income_document", docsSelect);

        if (refundCheck === true) {
            //환불 여부 Y && 계좌번호, 계좌주, 코드 다 있을 경우
            if (accountNumber && accountOwner && code) {
                incomeDeleteApi(queryParams);
            } else {
                //환불 여부 Y지만 환불 정보가 하나라도 비어있는 경우
                alert(`비어있는 정보가 있습니다. [${emptiedCheck()}]`);
            };
        } else {
            //환불 정보 N
            incomeDeleteApi(queryParams);
        };
    };

    //최종 렌더링
    return (
        <div>
            <Button 
                outline 
                color="secondary" 
                size="sm" 
                onClick={modalToggle} 
                className={`${props.isActive ? "" : "notActive"}`} 
            >삭제</Button>
            <Modal isOpen={modal} fade={false} toggle={modalToggle} className="modal-dialog-centered">
                <ModalHeader toggle={modalToggle}>급여 삭제 추가 정보</ModalHeader>
                <ModalBody>
                    { props.income.scheduled_payment_uuid &&
                        <>
                            <p>환불 여부</p>
                            <RadioComponent name="refundCheckRadio" value={refundCheck} onClick={setRefundCheck} />
                            <Fade in={refundCheck}>
                                <hr style={{width:"100%"}} />
                                <FormGroup id="refundInfo" className={(refundCheck === false) ? `notActive` : ""}>
                                    <p>환불 정보 입력</p>
                                    <InputComponent 
                                        title="계좌번호" 
                                        type="text" 
                                        onKeyPress={onKeyPress} 
                                        value={accountNumber} 
                                        onChange={handleChangeAccount} 
                                        placeholder="환불 계좌 번호 입력"
                                    />
                                    <InputComponent 
                                        title="은행코드" 
                                        type="select" 
                                        value={code} 
                                        onChange={handleChangeBankCode} 
                                        contents={contents}
                                    />
                                    <InputComponent 
                                        title="계좌주명" 
                                        type="text" 
                                        value={accountOwner} 
                                        onChange={handleChangeOwner} 
                                        placeholder="예금주명 입력"
                                    />
                                </FormGroup>
                            </Fade>
                            <hr style={{width:"100%"}} />
                        </>
                    }
                    <p>급여관련 문서 삭제 여부</p>
                    <RadioComponent name="deleteDocRadio" value={docsSelect} onClick={setDocsSelect} />
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" style={{float:"right"}} onClick={handleDeleteIncome}>제출</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

//radio component. name, value(state), onclick(setState)를 props로 받음.
const RadioComponent = (props) => {
    return (
        <FormGroup tag="fieldset" className="radio-form">
            <FormGroup check>
                <Label>
                    <Input 
                        type="radio" 
                        name={props.name}
                        value={props.value === true}
                        onClick={() => props.onClick(true)}
                        disabled={props.disabled}
                    />
                    <span className="radio-design"></span>
                    <span className="radio-text">예</span>
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label>
                    <Input 
                        type="radio" 
                        name={props.name}
                        value={props.value === false}
                        onClick={() => props.onClick(false)}
                        disabled={props.disabled}
                        defaultChecked
                    />
                    <span className="radio-design" defaultChecked></span>
                    <span className="radio-text">아니오</span>
                </Label>
            </FormGroup>
        </FormGroup>
    );
};

//input component. title, type, onkeypress(option), value, onchange, placeholder(option), contents(option)을 props로 받음
const InputComponent = (props) => {
    return (
        <InputGroup>
            <InputGroupAddon addonType="prepend">
                <InputGroupText>{props.title}</InputGroupText>
            </InputGroupAddon>
            <Input 
                type={props.type}
                onKeyPress={props.onKeyPress}
                value={props.value}
                onChange={props.onChange}
                placeholder={props.placeholder}
            >
                {props.contents}
            </Input>
        </InputGroup>
    );
};

export default IncomeRefundModal;