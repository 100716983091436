import { 
    GET_PAYMENT_LIST,
    GET_PAYMENT_LIST_SUCCESS,
    GET_PAYMENT_LIST_ERROR,
    GET_PAYMENT_ONE,
    GET_PAYMENT_ONE_SUCCESS,
    GET_PAYMENT_ONE_ERROR,
} from '../_actions/_types';

//초깃값
const initialState = {
    payments : {
        loading : null,
        count: null,
        data: [],
        currentPage: 1,
        pageChunk: 0,
        error: null,
    },
    payment : {
        loading : null,
        count: null,
        data: null,
        error: null,
    }
}

//청구서 리듀서
export default function paymentReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PAYMENT_LIST:
            return {
                ...state,
                payments: {
                    loading: 'loading...',
                    count: null,
                    data: null,
                    error: null,
                }
            };
        case GET_PAYMENT_LIST_SUCCESS:
            return {
                ...state,
                payments: {
                    loading: 'loading success...',
                    count: action.data[0].count,
                    data: action.data[0].results,
                    currentPage: action.data[1],
                    pageChunk: action.data[2],
                    error: null,
                }
            };
        case GET_PAYMENT_LIST_ERROR:
            return {
                ...state,
                payments: {
                    loading: 'loading error...',
                    count: null,
                    data: null,
                    error: action.error,
                }
            };
        case GET_PAYMENT_ONE:
            return {
                ...state,
                payment: {
                    loading: 'loading...',
                    count: null,
                    data: null,
                    error: null,
                }
            };
        case GET_PAYMENT_ONE_SUCCESS:
            return {
                ...state,
                payment: {
                    loading: 'loading success...',
                    count: null,
                    data: action.data,
                    error: null,
                }
            };
        case GET_PAYMENT_ONE_ERROR:
            return {
                ...state,
                payment: {
                    loading: 'loading error...',
                    count: null,
                    data: null,
                    error: action.error,
                }
            };
        default:
            return state;
    }
};

