import pdfImage from '../../assets/img/PDF_file_icon.png';

export const offset = 0; 
export const pageLimit = 10;
export const pageSlice = (offset - 1) * 10;
export const currDay = 24 * 60 * 60 * 1000;
export const currMonth = currDay * 30;
const dayRegExp = /^(\d{4})(\d{2})(\d{2})$/; //ex.YYYYMMDD 형태의 정규식

//파라미터 arr가 배열인지 확인하는 함수
const isArray = function (arr) {
    return Array.isArray(arr);
};

//파라미터 obj가 객체인지 확인하는 함수
const isObject = function (obj) {
    return obj !== null && typeof obj === 'object' && !isArray(obj);
};

//snake case나 kebab case인 단순한 문자열을 받아 camel case로 변환하는 함수
const stringToCamel = (str) => {
    return str.replace(/([-_]\w)/g, g => g[1].toUpperCase())
};

//snake case 키를 가진 객체를 통으로 받아 camel case로 바꿔주는 재귀함수
export const convertSnakeToCamel = (obj) => {
    if (isObject(obj)) {
        const newObject = {};

        Object.keys(obj)
            .forEach((key) => {
                newObject[stringToCamel(key)] = convertSnakeToCamel(obj[key]);
            });

        return newObject;
    } else if (isArray(obj)) {
        return obj.map((i) => {
            return convertSnakeToCamel(i);
        });
    };

    return obj;
};

//현재 - 들어온 date 차이 일 수를 구하는 함수
export const commonDateCal = (date) => {
  let now = new Date();
  let compareDate;
  if (dayRegExp.test(date)){
    compareDate = new Date(autoHyphenDate(date));
  } else {
    compareDate = new Date(date);
  }
  return Math.ceil((now - compareDate)/currDay);
}

// 7일 이전에 생성된 데이터 앞에 new 마크를 붙여주는 함수
export const compareLatest = (date) => {
  if(commonDateCal(date) <= 7) {
      return(
          <span style={{color:'red'}}>new</span>
      )
  }
  return '';
};

//채권명_이름으로 들어오는 데이터를 채권명만 나오도록 정제하는 함수
export const userCourse = (bondName) => {
  if(bondName) {
      let course = bondName.split('_');
      course = course[0];
      return course;
  }
  return '';
};

//년-월-일 포맷으로 만들어주는 함수
export const autoHyphenDate = (value) => {
  if(value === undefined) {
    return '';
  } else if (dayRegExp.test(value)){
    return String(value).replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
  } else {
    let tz_offset = (new Date()).getTimezoneOffset() * 60000;
    return new Date(new Date(value) - tz_offset).toISOString().slice(0, 10);
  }
};

//세 자리 숫자마다 ,를 찍어주는 함수
export const commonCost = (cost) => {
    if(typeof(cost) === 'number'){
      return cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else if (typeof(cost) === 'string') {
      return cost.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return '';
    // let regexp = /\B(?=(\d{3})+(?!\d))/g;
    // return Number(string).toFixed(0).replace(regexp, ",");
};

//, 제거 함수
export const removeComma = (value) => {
  if (!value) return "0";
  return String(parseInt(value.replace(/,/g, "")));
};

//- 제거 함수
export const removeHypen = (value) => {
  if (!value) return "";
  return String(value.replace(/-/g, ""));
};

//000-0000-0000 포맷으로 만들어주는 함수
export const autoHyphenPhone = (value) => {

  if(value){
      value = String(value).replace(/[^0-9]/g, "");
      let seoul = false;
      let tmp = "";

      if (value.substr(0, 2) === "02") {
        seoul = true;
      }
    
      if (value.length < 3) {
        return value;
      } else if (value.length < 6) {
        tmp += value.substr(0, 2);
        tmp += "-";
        tmp += value.substr(2);
        return tmp;
      } else if (value.length < 10) {
        tmp += value.substr(0, 2);
        tmp += "-";
        tmp += value.substr(2, 3);
        tmp += "-";
        tmp += value.substr(5);
        return tmp;
      } else if (value.length < 11) {
        if (seoul) {
          tmp += value.substr(0, 2);
          tmp += "-";
          tmp += value.substr(2, 4);
        } else {
          tmp += value.substr(0, 3);
          tmp += "-";
          tmp += value.substr(3, 3);
        }
        tmp += "-";
        tmp += value.substr(6);
        return tmp;
      } else {
        tmp += value.substr(0, 3);
        tmp += "-";
        tmp += value.substr(3, 4);
        tmp += "-";
        tmp += value.substr(7);
        return tmp;
      }
  }   
  return '';
};

//확장자 제거 함수
export const removeExtension = (title) => {
  return title.split('.')[0];
};

//현재 기준으로 n일 전 날짜를 구하는 함수
export const daysIntervalCal = (days) => {
  let now = new Date();
  let dayOfMonth = now.getDate();
  now.setDate(dayOfMonth - days);
  return now;
};

//사진 클릭 시 원본 크기로 보여지게 하는 함수
export const bigPhoto = (url) => {
  let img = new Image();
  img.src = url;
  let fixed_width = '1000px';
  let fixed_height = '1000px';
  let OpenWindow=window.open('','_blank', `width=${fixed_width}, height=${fixed_height}, menubars=no, scrollbars=auto`);
  OpenWindow.document.write(`
  <style>body{margin:0px;}</style>
  <div>
  <img src="${img.src}" width="${fixed_width}" />
  </div>`);
};

//이미지 뷰어 component
export const ImageViewer = (props) => {

  // .jpeg, .jpg, .jfif, .png 확장자 파일만 골라서 배열로 필터링
  const resultFilterArr = props.arr.filter(data => (
    data.title.includes('.jpeg') || data.title.includes('.jpg') || data.title.includes('.jfif') || data.title.includes('.png') || data.title.includes('.pdf')
  ));
  
  //이미지 뷰어에 들어갈 content 렌더링
  let content = resultFilterArr.map(data => {
    return (data.title.includes('.pdf')) ? 
      // pdf일 경우
      <div key={data.url}>
        <img src={pdfImage} className="image-viewer" alt={data.title} width="50%" />
        <p className="legend" onClick={() => window.open(data.url)} style={{cursor:"pointer"}}>{data.title}</p>
      </div> : 
      //pdf 제외 나머지 확장자
      <div key={data.url} onClick={() => {bigPhoto(data.url)}}>
        <img src={data.url} className="image-viewer" alt={data.title} />
        <p className="legend">{data.title}</p>
      </div>
  });

  return (
      <>
          <props.Carousel>
              {content}
          </props.Carousel>
      </>
  );
};

//성별에 따라 출력해주는 함수
export const genderTrans = (gender) => {
  switch (gender) {
    case 'Female':
      return '여성';
    case 'Male':
      return '남성';
    default:
      return '알 수 없음';
  }
};

// 주소 출력 함수. 없으면 공란으로 처리
export const addressTrans = (address) => {
  return address || '';
};

//pagination에서 이용하는, 페이지의 수가 들어오면 페이지의 수를 원소로 해 배열을 만들어주는 함수 (ex. 3 => [1, 2, 3])
export const createPageArr = (num) => {
  return Array.from({length: num}, (x, i) => i + 1);
};
