import React, {useState} from 'react';
import { Button, Form, Col, Row, Label } from 'reactstrap';
import DatePicker from 'react-date-picker';
import { removeHypen, pageSlice, pageLimit, daysIntervalCal, autoHyphenDate } from '../../common/common';
import { useDispatch } from 'react-redux';
import { getEmploySearch } from '../../../_actions/search_action';
import { initialState } from '../../../_reducers/search_reducer';
import { getMode } from '../../../_actions/mode_action';
import { withRouter } from 'react-router';


const EmploySearch = (props) => {

    const dispatch = useDispatch();
    const [isActive, setIsActive] = useState(true);
    const [side, setSide] = useState(false);
    const [party__username, setPartyUsername] = useState('');
    const [party__phone, setPartyPhone] = useState('');
    const [employment_status, setEmploymentStatus] = useState('');
    const [bond_name, setBondName] = useState('');
    const [valid_from_lowerbound, setValidFromLowerbound] = useState('');
    const [valid_from_upperbound, setValidFromUpperbound] = useState('');
    const [valid_to_lowerbound, setValidToLowerbound] = useState('');
    const [valid_to_upperbound, setValidToUpperbound] = useState('');
    const [created_time_lowerbound, setCreatedTimeLowerbound] = useState('');
    const [created_time_upperbound, setCreatedTimeUpperbound] = useState('');
    const { currentPage, pageChunk } = initialState.employSearches;

    const [query_array, setQuerayArray] = useState({
        party__username: '',
        party__phone: '',
        employment_status: '',
        bond_name: '',
        valid_from_lowerbound: '',
        valid_from_upperbound: '',
        valid_to_lowerbound: '',
        valid_to_upperbound: '',
        created_time_lowerbound: '',
        created_time_upperbound: '',
    })

    const handleActive = () => {
        setIsActive(!isActive);
        setSide(!side);
    }

    const handleOnChange = (e) => {
        e.preventDefault();
        let { name, value } = e.target;

        if (name === 'party__username') {
            setPartyUsername(value);
        } else if (name === 'party__phone') {
            setPartyPhone(removeHypen(value));
        } else if (name === 'employment_status') {
            setEmploymentStatus(value);
        } else if (name === 'bond_name') {
            setBondName(value);
        }

        setQuerayArray({
            ...query_array,
            [name] : value
        });
    }

    const querySet = (value1, value2, func1, func2) => {
        setQuerayArray({
            ...query_array,
            [value1]: `${autoHyphenDate(func1)}T00:00:00`,
            [value2]: `${autoHyphenDate(func2)}T23:59:59`,
        });
    }

    const querySet2 = (name,year,month,date,value) => {
        setQuerayArray({
            ...query_array,
            [name] : `${year}-${month}-${date}${value}`
        });
    }

    const renderDayContents = (day, name) => {
        
        let year = new Date(day).getFullYear();
        let month = new Date(day).getMonth() + 1;
        let date = new Date(day).getDate();

        if(name.indexOf('_lowerbound') !== -1){
            querySet2(name, year, month, date, 'T00:00:00');
        } else if(name.indexOf('_upperbound') !== -1) {
            querySet2(name, year, month, date, 'T23:59:59');
        }
    }

    const handleDate = (e) => {
        e.preventDefault();
        let { value } = e.target;
        setCreatedTimeUpperbound(new Date());
        setCreatedTimeLowerbound(daysIntervalCal(value));
        querySet('created_time_lowerbound', 'created_time_upperbound', daysIntervalCal(value), daysIntervalCal(0));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const arr = [];
        for(let key in query_array){
            if(query_array[key]){
                if(key === 'party_phone') {
                    arr.push(`${key}=${removeHypen(query_array[key])}`);
                } else if (key.indexOf('_status') !== -1) {
                    if(query_array[key] !== 'null') {
                        arr.push(`${key}=${query_array[key]}`);
                    }
                } else {
                    arr.push(`${key}=${query_array[key]}`);
                }
            }
        };
        dispatch(getEmploySearch(arr.join('&'), pageSlice, pageLimit, currentPage, pageChunk));
        dispatch(getMode('search'));
        props.history.push('/employment?page=1');
    };

    const handleReset = (e) => {
        e.preventDefault();
        let { name } = e.target;
        setPartyUsername('');
        setPartyPhone('');
        setBondName('');
        setEmploymentStatus('null');
        setValidFromLowerbound('');
        setValidFromUpperbound('');
        setValidToLowerbound('');
        setValidToUpperbound('');
        setCreatedTimeLowerbound('');
        setCreatedTimeUpperbound('');

        setQuerayArray({
            [name] : '',
        })
    }

    return (
        <div className="search-box">
            <div style={{display:'grid'}}>
                <div className="search-sub">
                    <h3 className="search-title">
                        <div onClick={handleActive} className={`arrow ${side ? "up" : "down"}`}></div>
                    </h3>
                    <hr className="search-hr"/>
                </div>
                <Form 
                    className={`form-table ${isActive ? "active" : "notActive"}`}
                    onSubmit={handleSubmit}
                    onReset={handleReset}
                >
                    <Row>
                        <Col>
                            <Label for="party_usename">이름</Label>
                            <input
                                name="party__username" 
                                value={party__username}
                                onChange={handleOnChange}
                                type="string" 
                            />
                        </Col>
                        <Col>
                            <Label for="party_phone">연락처</Label>
                            <input 
                                name="party__phone"
                                value={party__phone}
                                onChange={handleOnChange}
                                type="tel"
                                pattern="[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}"
                            />
                        </Col>
                        <Col>
                            <Label for="bond_name">과정명</Label>
                            <input 
                                name="bond_name"
                                value={bond_name}
                                onChange={handleOnChange}
                                type="string"
                            />
                        </Col>
                        <Col>
                            <Label for="employment_status">취업상태</Label>
                            <select 
                                name="employment_status"
                                value={employment_status}
                                onChange={handleOnChange}
                            >
                                <option value="null">전체</option>
                                <option value="4">취업</option>
                                <option value="5">퇴사</option>
                                <option value="6">미취업</option>
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label for="valid_from">입사일</Label>
                            <DatePicker
                                name="employment_status_valid_from_lowerbound"
                                value={valid_from_lowerbound}
                                clearIcon={null}
                                onChange={(date) => {setValidFromLowerbound(date); renderDayContents(date, 'employment_status_valid_from_lowerbound'); }}
                            />
                            <span> ~ </span>
                            <DatePicker
                                name="employment_status_valid_from_upperbound"
                                value={valid_from_upperbound}
                                clearIcon={null}
                                onChange={date => {setValidFromUpperbound(date); renderDayContents(date, 'employment_status_valid_from_upperbound'); }}
                            />
                        </Col>
                        <Col>
                            <Label for="valid_to">퇴사일</Label>
                            <DatePicker
                                name="leave_status_valid_from_lowerbound"
                                value={valid_to_lowerbound}
                                clearIcon={null}
                                onChange={date => {setValidToLowerbound(date); renderDayContents(date, 'leave_status_valid_from_lowerbound'); }}
                            />
                            <span> ~ </span>
                            <DatePicker
                                name="leave_status_valid_from_lowerbound"
                                value={valid_to_upperbound}
                                clearIcon={null}
                                onChange={date => {setValidToUpperbound(date); renderDayContents(date, 'leave_status_valid_from_lowerbound'); }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label for="created-time">등록일</Label>
                            <DatePicker
                                name="created_time_lowerbound"
                                value={created_time_lowerbound}
                                clearIcon={null}
                                onChange={date => {setCreatedTimeLowerbound(date); renderDayContents(date, 'created_time_lowerbound'); }}
                            />
                            <span> ~ </span>
                            <DatePicker
                                name="created_time_upperbound"
                                value={created_time_upperbound}
                                clearIcon={null}
                                onChange={date => {setCreatedTimeUpperbound(date); renderDayContents(date, 'created_time_upperbound'); }}
                            />
                            <Button size="sm" onClick={handleDate} value={7} className="date-button" >1주일</Button>
                            <Button size="sm" onClick={handleDate} value={30} className="date-button" >1개월</Button>
                            <Button size="sm" onClick={handleDate} value={60} className="date-button" >2개월</Button>
                        </Col>
                    </Row>
                    <Row>
                        <div className="search-button-wrapper">
                            <Button color="success" className="search-submit" type="submit">검색하기</Button>
                            <Button color="white" className="search-reset" type="reset">초기화</Button>
                        </div>
                    </Row>
                </Form>
            </div>
        </div>
    )
};

export default withRouter(EmploySearch);
