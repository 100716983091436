import Axios from 'axios';
import { URL_PRESENT } from './URL';
import { headerConfig } from './cookie/common';

//특정 채권의 납부 URL을 발행하는 api
export const uploadURL = async (id) => {
    const response = await Axios.post(
        `${URL_PRESENT}/member/party/${id}/token`, 
        null, 
        headerConfig()
    );
    return response.data;
};

//근로계약서에 관련된 데이터를 가져오는 api
export const getEmploymentS3URL = async (uuid) => {
    const response = await Axios.get(
        `${URL_PRESENT}/employment/${uuid}/document`, 
        headerConfig()
    );
    return response.data;
};

//급여명세서에 관련된 데이터를 가져오는 api
export const getIncomeS3URL = async (uuid) => {
    const response = await Axios.get(
        `${URL_PRESENT}/employment/income/${uuid}/document`, 
        headerConfig()
    );
    return response.data;
};

//blob로 저장된 이미지 및 파일을 URL로 표출하는 함수
export const getFileLoad = async (data) => {
    return await Axios.get(`${data.s3_url}`, { 
        responseType: "blob",
        headers: {
            "x-amz-server-side-encryption-customer-algorithm" : "AES256",
            "x-amz-server-side-encryption-customer-key" : `"${data.encrypted_key_b64}"`,
            "x-amz-server-side-encryption-customer-key-MD5" : `"${data.encrypted_key_md5}"`,
        }
     })
    .then(response => {
        return URL.createObjectURL(response.data);
    })
};

//arraybuffer로 저장된 pdf 파일을 URL로 표출하는 함수
export const getPdfLoad = async (data) => {
    return await Axios.get(`${data.s3_url}`, { 
        responseType: "arraybuffer",
        headers: {
            "x-amz-server-side-encryption-customer-algorithm" : "AES256",
            "x-amz-server-side-encryption-customer-key" : `"${data.encrypted_key_b64}"`,
            "x-amz-server-side-encryption-customer-key-MD5" : `"${data.encrypted_key_md5}"`,
        }
     })
    .then(response => {
        let file = new Blob([response.data], {type: 'application/pdf'});
        return URL.createObjectURL(file);;
    })
};
