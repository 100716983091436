import {
    MODE_READ,
    MODE_CREATE,
    MODE_UPDATE,
    MODE_SEARCH
} from '../_actions/_types';

//초깃값
const initialState = {
    mode : 'read'
}

//모드 리듀서
export default function modeReducer(state = initialState, action) {
    switch (action.type) {
        case MODE_READ:
            return {
                ...state,
                mode : 'read'
            };
        case MODE_CREATE:
            return {
                ...state,
                mode : 'create'
            };
        case MODE_UPDATE:
            return {
                ...state,
                mode : 'update'
            };
        case MODE_SEARCH:
            return {
                ...state,
                mode : 'search'
            }
        default:
            return state;
    }
};