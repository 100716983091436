import {
    MODE_READ,
    MODE_CREATE,
    MODE_UPDATE,
    MODE_SEARCH
} from './_types';


export const getMode = (mode) => async dispatch => {
    if(mode === 'create') {
        dispatch({ type: MODE_CREATE, mode});
    } else if(mode === 'update') {
        dispatch({ type: MODE_UPDATE, mode});
    } else if(mode === 'search') {
        dispatch({ type: MODE_SEARCH, mode});
    } else {
        dispatch({ type: MODE_READ, mode });
    }
};