import Axios from 'axios';
import { URL_PRESENT } from './URL';
import { headerConfig } from './cookie/common';

//청구서 데이터를 가져오는 api
export const getPaymentPart = async (offset, pageLimit, currentPage, pageChunk) => {
    const response = await Axios.get(
        `${URL_PRESENT}/payment/scheduled-payment/?offset=${offset}&limit=${pageLimit}`, 
        headerConfig()
    );
    const data = [];
    data.push(response.data, currentPage, pageChunk);
    return data;
};

//특정 청구서 데이터를 가져오는 api
export const getPaymentOne = async (uuid) => {
    const response = await Axios.get(
        `${URL_PRESENT}/payment/scheduled-payment/${uuid}`, 
        headerConfig()
    );
    return response.data;
};