import React, { useState, useEffect } from 'react';
import { Table, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { TableThread, TableExport, paymentUuidExistCheck } from './incomeParts/IncomeFun';
import { compareLatest, commonCost, autoHyphenPhone, userCourse, autoHyphenDate, } from '../common/common';
import IncomePayInfo from './incomeParts/IncomePayInfo';
import EmptyPage from '../common/EmptyPage';
import IncomeRefundModal from './incomeParts/IncomeRefundModal';
import ImageViewerModal from '../common/ImageViewerModal';

const IncomeTable = (props) => {
    const [incomeData, setIncomeData] = useState('');
    const [isActive, setIsActive] = useState(false);
    let searchResult = null;
    let searchCount = useSelector(state => state.searches.incomeSearches.count);
    
    useEffect(() => {
        let tr = document.getElementsByTagName('tr');
        let tdArr = [];
        for (let i = 1; i < tr.length; i++) {
            tdArr.push({
                "name" : tr[i].cells[1].innerHTML,
                "phone" : tr[i].cells[2].innerHTML,
                "course" : tr[i].cells[3].innerHTML,
                "companyName" : tr[i].cells[4].innerHTML,
                "incomeDate" : tr[i].cells[5].innerHTML,
                "incomeDocDate" : tr[i].cells[6].innerHTML,
                "monthlyIncome" : tr[i].cells[7].innerHTML,
            })
        };
        setIncomeData(tdArr);
    }, []);

    const handleDeleteButtonDisplay = () => {
        setIsActive(!isActive);
    };

    if (searchCount) {
        searchResult = <p style={{textAlign: "center", marginTop: "50px"}}>{searchCount} 개의 검색 결과가 있습니다.</p>;
    };
    
    if(props.data && props.data.length > 0){
        return (
            <div className="table-wrapper">
                 <div className="table-top-button-wrap">
                    <TableExport data={incomeData} />
                    <Button onClick={handleDeleteButtonDisplay} className="">급여정보 삭제</Button>
                 </div>
                <Table hover size="sm">
                    <TableThread />
                    <tbody>
                        {props.data.map((income) => {
                            return (
                                <tr align="center" key={income.uuid}>
                                    <td>{compareLatest(income.created_time)}</td>
                                    <td>{income.employment.party?.username}</td> 
                                    <td>{autoHyphenPhone(income.employment.party?.phone)}</td>
                                    <td>{userCourse(income.employment?.bond_name)}</td>
                                    <td>{income.employment?.company_name}</td>
                                    <td>{autoHyphenDate(income.income_date)}</td>
                                    <td>{autoHyphenDate(income.income_document_date)}</td>
                                    <td>{commonCost(income.income)}</td>
                                    <td><ImageViewerModal uuid={income.uuid} page="income" /></td>
                                    <td>{paymentUuidExistCheck(income.scheduled_payment_uuid)}</td>
                                    <td><IncomePayInfo payment_uuid={income.scheduled_payment_uuid} /></td>
                                    <td>
                                        <IncomeRefundModal isActive={isActive} income={income} />
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                {searchResult}
            </div>
        )
    } else if (props.data && props.data.length <= 0){
        return (
            <EmptyPage thread={<TableThread />} colspan={11} />
        );
    };

    return (
        <></>
    );

};

export default IncomeTable;