import React, { useState } from 'react';
import { Button, Form, Col, Row, Label } from 'reactstrap';
import DatePicker from 'react-date-picker';
import { removeHypen, pageSlice, pageLimit, daysIntervalCal, autoHyphenDate } from '../../common/common';
import { useDispatch } from 'react-redux';
import { getIncomeSearch } from '../../../_actions/search_action';
import { initialState } from '../../../_reducers/search_reducer';
import { getMode } from '../../../_actions/mode_action';
import { withRouter } from 'react-router';

const IncomeSearch = (props) => {

    const dispatch = useDispatch();
    const [isActive, setIsActive] = useState(true);
    const [side, setSide] = useState(false);
    const [employment__party__username, setEmploymentUsername] = useState('');
    const [employment__party__phone, setEmploymentPhone] = useState('');
    const [bond_name, setBondName] = useState('');
    const [income_date_lowerbound, setIncomeDateLowerBound] = useState('');
    const [income_date_upperbound, setIncomeDateUpperBound] = useState('');
    const [income_document_date_lowerbound, setDocumentDateLowerBound] = useState('');
    const [income_document_date_upperbound, setDocumentDateUpperBound] = useState('');
    const [is_linked_to_scheduled_payment, setIsLinkedToScheduledPayment] = useState('');
    const { currentPage, pageChunk } = initialState.incomeSearches;

    const [query_array, setQuerayArray] = useState({
        employment__party__username: '',
        employment__party__phone: '',
        bond_policy__deposit_policy__type: '',
        bond_name: '',
        income_date_lowerbound: '',
        income_date_upperbound: '',
        income_document_date_lowerbound: '',
        income_document_date_upperbound: '',
        is_linked_to_scheduled_payment: '',
    });

    const handleActive = () => {
        setIsActive(!isActive);
        setSide(!side);
    };

    const handleInputChange = (e) => {
        e.preventDefault();
        let { name, value } = e.target;

        if (name === 'employment__party__username') {
            setEmploymentUsername(value);
        } else if (name === 'employment__party__phone') {
            setEmploymentPhone(value);
        } else if (name === 'bond_name') {
            setBondName(value);
        } else if (name === 'is_linked_to_scheduled_payment') {
            setIsLinkedToScheduledPayment(value);
        }

        setQuerayArray({
            ...query_array,
            [name] : value
        });
    };

    const renderDayContents = (day, name) => {
        
        let year = new Date(day).getFullYear();
        let month = new Date(day).getMonth() + 1;
        let date = new Date(day).getDate();

        if(name.indexOf('_lowerbound') !== -1){
            querySet2(name, year, month, date, 'T00:00:00');
        } else if(name.indexOf('_upperbound') !== -1) {
            querySet2(name, year, month, date, 'T23:59:59');
        }
    };

    const querySet = (value1, value2, func1, func2 ) => {
        setQuerayArray({
            ...query_array,
            [value1]: `${autoHyphenDate(func1)}T00:00:00`,
            [value2]: `${autoHyphenDate(func2)}T23:59:59`,
        });
    }

    const querySet2 = (name,year,month,date,value ) => {
        setQuerayArray({
            ...query_array,
            [name] : `${year}-${month}-${date}${value}`
        });
    }

    const incomeHandleDate = (e) => {
        e.preventDefault();
        let value = e.target.value.split(',');
        if(value[1].indexOf('document') !== -1){
            setDocumentDateUpperBound(new Date());
            setDocumentDateLowerBound(daysIntervalCal(value[0]));
        }else{
            setIncomeDateUpperBound(new Date());
            setIncomeDateLowerBound(daysIntervalCal(value[0]));
        }
        querySet(value[1], value[2], daysIntervalCal(value[0]), daysIntervalCal(0));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const arr = [];
        for(let key in query_array){
            if(query_array[key]){
                if(key === 'employment__party__phone') {
                    arr.push(`${key}=${removeHypen(query_array[key])}`);
                } else if (key === 'is_linked_to_scheduled_payment') {
                    if(query_array[key] !== 'null') {
                        arr.push(`${key}=${query_array[key]}`);
                    }
                } else {
                    arr.push(`${key}=${query_array[key]}`);
                }
            }
        };
        dispatch(getIncomeSearch(arr.join('&'), pageSlice, pageLimit, currentPage, pageChunk));
        dispatch(getMode('search'));
        props.history.push('/income?page=1');
    }

    const handleReset = (e) => {
        e.preventDefault();
        let { name } = e.target;
        
        setEmploymentUsername('');
        setEmploymentPhone('');
        setBondName('');
        setIncomeDateLowerBound('');
        setIncomeDateUpperBound('');
        setDocumentDateLowerBound('');
        setDocumentDateUpperBound(''); 
        setIsLinkedToScheduledPayment('null');       

        setQuerayArray({
            [name] : '',
        })
    }

    return (
        <div className="search-box">
            <div style={{display:'grid'}}>
                <div className="search-sub">
                    <h3 className="search-title"><div onClick={handleActive} className={`arrow ${side ? "up" : "down"}`}></div></h3>
                    <hr className="search-hr"/>
                </div>
                <Form 
                    className={`form-table ${isActive ? "active" : "notActive"} income`} 
                    onSubmit={handleSubmit}
                    onReset={handleReset}
                >
                    <Row>
                        <Col>
                            <Label for="employmentUsername">이름</Label>
                            <input 
                                name="employment__party__username"
                                value={employment__party__username} 
                                onChange={handleInputChange} 
                                type="string" 
                            />
                        </Col>
                        <Col>
                            <Label for="employmentPhone">연락처</Label>
                            <input 
                                name="employment__party__phone"
                                value={employment__party__phone} 
                                onChange={handleInputChange}
                                type="tel"
                                pattern="[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}"
                            />
                        </Col>
                        <Col>
                            <Label for="bondName">과정명</Label>
                            <input 
                                name="bond_name"
                                value={bond_name}
                                onChange={handleInputChange} 
                                type="string" 
                            />
                        </Col>
                        <Col>
                            <Label for="is_linked_to_scheduled_payment">청구서발행여부</Label>
                            <select 
                                name="is_linked_to_scheduled_payment"
                                value={is_linked_to_scheduled_payment}
                                onChange={handleInputChange}
                            >
                                <option value="null">전체</option>
                                <option value="true">Y</option>
                                <option value="false">N</option>
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label for="incomeDate">급여지급일</Label>
                            <DatePicker
                                name="income_date_lowerbound"
                                value={income_date_lowerbound}
                                clearIcon={null}
                                onChange={(date) => {setIncomeDateLowerBound(date); renderDayContents(date, 'income_date_lowerbound'); }}
                            />
                            <span> ~ </span>
                            <DatePicker
                                name="income_date_upperbound"
                                value={income_date_upperbound}
                                clearIcon={null}
                                onChange={(date) => {setIncomeDateUpperBound(date); renderDayContents(date, 'income_date_upperbound'); }}
                            />
                            <Button size="sm" onClick={incomeHandleDate} value={[7, "income_date_lowerbound", "income_date_upperbound"]} className="date-button" >1주일</Button>
                            <Button size="sm" onClick={incomeHandleDate} value={[30, "income_date_lowerbound", "income_date_upperbound"]} className="date-button" >1개월</Button>
                            <Button size="sm" onClick={incomeHandleDate} value={[60, "income_date_lowerbound", "income_date_upperbound"]} className="date-button" >2개월</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label for="incomeDocDate">명세서제출일</Label>
                            <DatePicker
                                name="income_document_date_lowerbound"
                                value={income_document_date_lowerbound}
                                clearIcon={null}
                                onChange={(date) => {setDocumentDateLowerBound(date); renderDayContents(date, 'income_document_date_lowerbound'); }}
                            />
                            <span> ~ </span>
                            <DatePicker
                                name="income_document_date_upperbound"
                                value={income_document_date_upperbound}
                                clearIcon={null}
                                onChange={(date) => {setDocumentDateUpperBound(date); renderDayContents(date, 'income_document_date_upperbound'); }}
                            />
                            <Button size="sm" onClick={incomeHandleDate} value={[7, "income_document_date_lowerbound", "income_document_date_upperbound"]} className="date-button" >1주일</Button>
                            <Button size="sm" onClick={incomeHandleDate} value={[30, "income_document_date_lowerbound", "income_document_date_upperbound"]} className="date-button" >1개월</Button>
                            <Button size="sm" onClick={incomeHandleDate} value={[60, "income_document_date_lowerbound", "income_document_date_upperbound"]} className="date-button" >2개월</Button>
                        </Col>
                    </Row>
                    <Row>
                        <div className="search-button-wrapper">
                            <Button color="success" className="search-submit" type="submit">검색하기</Button>
                            <Button color="white" className="search-reset" type="reset">초기화</Button>
                        </div>
                    </Row>
                </Form>
            </div>
        </div>
    )
};

export default withRouter(IncomeSearch);
