import { 
    GET_BONDS,
    GET_BONDS_SUCCESS,
    GET_BONDS_ERROR
} from '../_actions/_types';

//초깃값
const initialState = {
    bonds : {
        loading : null,
        count: null,
        data: [],
        error: null,
        currentPage: 1,
        pageChunk: 0,
    }
}

//채권 리듀서
export default function bondReducer(state = initialState, action) {
    switch (action.type) {
        case GET_BONDS:
            return {
                ...state,
                bonds: {
                    loading: 'loading...',
                    data: null,
                    error: null,
                }
            };
        case GET_BONDS_SUCCESS:
            return {
                ...state,
                bonds: {
                    loading: 'loading success...',
                    count: action.data[0].count,
                    data: action.data[0].results,
                    currentPage: action.data[1],
                    pageChunk: action.data[2],
                    error: null,
                }
            };
        case GET_BONDS_ERROR:
            return {
                ...state,
                data: {
                    loading: 'loading error...',
                    data: null,
                    error: action.error,
                }
            };
        default:
            return state;
    }
};

