import React from 'react';
import { withRouter } from 'react-router';

const UpdateForm = ({uuid}) => {

    return(
        <div>
            Updateform
        </div>
    )
}

export default withRouter(UpdateForm);