import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { useSelector } from 'react-redux';
import { compareLatest, commonCost, commonDateCal, autoHyphenPhone, userCourse, autoHyphenDate } from '../common/common';
import { paymentStatus, paymentType, TableThread, TableExport } from './PaymentFunc';
import EmptyPage from '../common/EmptyPage';

const PaymentTable = ({data}) => {

    const [paymentData, setPaymentData] = useState([]);
    let searchCount = useSelector(state => state.searches.paymentSearches.count);

    useEffect(() => {
        let tr = document.getElementsByTagName('tr');
        let tdArr = [];
    
        for(let i = 1; i < tr.length; i++) {
            tdArr.push({
                "createdTime" : tr[i].cells[1].innerHTML,
                "name" : tr[i].cells[2].innerHTML,
                "phone" : tr[i].cells[3].innerHTML,
                "course" : tr[i].cells[4].innerHTML,
                "scheduledPaymentType" : tr[i].cells[5].innerHTML,
                "paymentTurn" : tr[i].cells[6].innerHTML,
                "paymentDueDate" : tr[i].cells[7].innerHTML,
                "paymentRealDate" : tr[i].cells[8].innerHTML,
                "paymentAmount" : tr[i].cells[9].innerHTML,
                "scheduledPaymentStatus" : tr[i].cells[10].innerHTML,
                "paymentDueDateSince" : tr[i].cells[11].innerHTML,
            })
        };
        setPaymentData(tdArr);
    }, [])

    let search_result = null;
    if(searchCount){
        search_result = <p style={{textAlign: 'center', marginTop: '50px'}}>{searchCount} 개의 검색 결과가 있습니다.</p>;
    }

    if(data && data.length > 0){
        return (
            <div className="table-wrapper">
                <TableExport data={paymentData} />
                <Table hover size="sm">
                    <TableThread />
                    <tbody>
                        {data.map((scheduledPayment) => {
                            let payment_status = scheduledPayment.scheduled_payment_status;
                            return (
                                <tr align="center" key={scheduledPayment.uuid}>
                                    <td>{compareLatest(scheduledPayment.created_time)}</td>
                                    <td>{autoHyphenDate(scheduledPayment.created_time)}</td>
                                    <td>{scheduledPayment.bond.payer_uuid.username}</td>
                                    <td>{autoHyphenPhone(scheduledPayment.bond.payer_uuid.phone)}</td>
                                    <td>{userCourse(scheduledPayment.bond.name)}</td>
                                    <td>{paymentType(scheduledPayment.scheduled_payment_type)}</td>
                                    <td>{scheduledPayment.payment_turn}</td>
                                    <td>{scheduledPayment?.payment_due_date}</td>
                                    <td>{(payment_status && payment_status?.scheduled_payment_status === 3) ? autoHyphenDate(payment_status?.valid_from) : ''}</td>
                                    <td>{commonCost(scheduledPayment.payment_amount)}</td>
                                    <td>{paymentStatus(payment_status)}</td>
                                    {/* includes를 사용해 배열[4,6]이 특정 요소status?.scheduled_payment_status를 포함하는지 판별 */}
                                    <td>{(payment_status && [4,6].includes(payment_status?.scheduled_payment_status)) ? `${commonDateCal(scheduledPayment?.payment_due_date)}일` : ''}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                {search_result}
            </div>
        )
    } else if(data && data.length <= 0){
        return (
            <EmptyPage thread={<TableThread />} colspan={12} />
        )
    }
    return(
        <></>
    )
};

export default PaymentTable;