import * as searchAPI from '../api/searchAPI';
import {
    SEARCH_START,
    SEARCH_SUCCESS_PAYER,
    SEARCH_SUCCESS_REQUESTER,
    SEARCH_ERROR,
    BOND_SEARCH_START,
    BOND_SEARCH_SUCCESS,
    BOND_SEARCH_ERROR,
    EMPLOY_SEARCH_START,
    EMPLOY_SEARCH_SUCCESS,
    EMPLOY_SEARCH_ERROR,
    INCOME_SEARCH_START,
    INCOME_SEARCH_SUCCESS,
    INCOME_SEARCH_ERROR,
    PAYMENT_SEARCH_START,
    PAYMENT_SEARCH_SUCCESS,
    PAYMENT_SEARCH_ERROR,
    MEMBER_SEARCH_START,
    MEMBER_SEARCH_SUCCESS,
    MEMBER_SEARCH_ERROR,

} from './_types';

//채권 생성 시 검색에 사용되는 action 생성자 함수 (안 쓰임)
export const getSearchView = (keyword) => async dispatch => {
    dispatch({ type: SEARCH_START });
    try{
        const data = await searchAPI.getSearch(keyword);
        dispatch({ type: SEARCH_SUCCESS_PAYER, data});
    } catch(e) {
        dispatch({ type: SEARCH_ERROR, e})
    }
};

//채권 생성 시 검색에 사용되는 action 생성자 함수 (안 쓰임)
export const getSearchView2 = (keyword) => async dispatch => {
    dispatch({ type: SEARCH_START });
    try{
        const data = await searchAPI.getSearch(keyword);
        dispatch({ type: SEARCH_SUCCESS_REQUESTER, data});
    } catch(e) {
        dispatch({ type: SEARCH_ERROR, e})
    }
};

//채권 관련 검색에 사용된는 action 생성자 함수
export const getBondSearch = (query, offset, limit, currentPage, pageChunk) => async dispatch => {
    dispatch({ type: BOND_SEARCH_START });
    try{
        const data = await searchAPI.bondSearch(query, offset, limit, currentPage, pageChunk);
        dispatch({ type: BOND_SEARCH_SUCCESS, data});
    } catch(e) {
        dispatch({ type: BOND_SEARCH_ERROR, e})
    }
};

//근로 관련 검색에 사용된는 action 생성자 함수
export const getEmploySearch = (query, offset, limit, currentPage, pageChunk) => async dispatch => {
    dispatch({ type: EMPLOY_SEARCH_START });
    try{
        const data = await searchAPI.employmentSearch(query, offset, limit, currentPage, pageChunk);
        dispatch({ type: EMPLOY_SEARCH_SUCCESS, data});
    } catch(e) {
        dispatch({ type: EMPLOY_SEARCH_ERROR, e})
    }
};

//급여 관련 검색에 사용된는 action 생성자 함수
export const getIncomeSearch = (query, offset, limit, currentPage, pageChunk) => async dispatch => {
    dispatch({ type: INCOME_SEARCH_START });
    try{
        const data = await searchAPI.incomeSearch(query, offset, limit, currentPage, pageChunk);
        dispatch({ type: INCOME_SEARCH_SUCCESS, data});
    } catch(e) {
        dispatch({ type: INCOME_SEARCH_ERROR, e})
    }
};

//청구서 관련 검색에 사용된는 action 생성자 함수
export const getPaymentSearch = (query, offset, limit, currentPage, pageChunk) => async dispatch => {
    dispatch({ type: PAYMENT_SEARCH_START });
    try{
        const data = await searchAPI.paymentSearch(query, offset, limit, currentPage, pageChunk);
        dispatch({ type: PAYMENT_SEARCH_SUCCESS, data});
    } catch(e) {
        dispatch({ type: PAYMENT_SEARCH_ERROR, e})
    }
};

//회원 관련 검색에 사용된는 action 생성자 함수
export const getMemberSearch = (query, offset, limit, currentPage, pageChunk) => async dispatch => {
    dispatch({ type: MEMBER_SEARCH_START });
    try{
        const data = await searchAPI.memberSearch(query, offset, limit, currentPage, pageChunk);
        dispatch({ type: MEMBER_SEARCH_SUCCESS, data});
    } catch(e) {
        dispatch({ type: MEMBER_SEARCH_ERROR, e})
    }
};
