
/* login */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

/* logout */
export const LOGGED_OUT = 'LOGGED_OUT';

/* auth check */
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_CHECK_SUCCESS = 'AUTH_CHECK_SUCCESS';
export const AUTH_CHECK_ERROR = 'AUTH_CHECK_ERROR';

/* bond */
export const GET_BONDS = 'GET_BONDS';
export const GET_BONDS_SUCCESS = 'GET_BONDS_SUCCESS';
export const GET_BONDS_ERROR = 'GET_BONDS_ERROR';

/* mode */
export const MODE_READ = 'MODE_READ';
export const MODE_CREATE = 'MODE_CREATE';
export const MODE_UPDATE = 'MODE_UPDATE';
export const MODE_SEARCH = 'MODE_SEARCH';

/* search */
export const SEARCH_START = 'SEARCH_START';
export const SEARCH_SUCCESS_PAYER = 'SEARCH_SUCCESS_PAYER';
export const SEARCH_SUCCESS_REQUESTER = 'SEARCH_SUCCESS_REQUESTER';
export const SEARCH_ERROR = 'SEARCH_ERROR';

export const BOND_SEARCH_START = 'BOND_SEARCH_START';
export const BOND_SEARCH_SUCCESS = 'BOND_SEARCH_SUCCESS';
export const BOND_SEARCH_ERROR = 'BOND_SEARCH_ERROR';

export const EMPLOY_SEARCH_START = 'EMPLOY_SEARCH_START';
export const EMPLOY_SEARCH_SUCCESS = 'EMPLOY_SEARCH_SUCCESS';
export const EMPLOY_SEARCH_ERROR = 'EMPLOY_SEARCH_ERROR';

export const INCOME_SEARCH_START = 'INCOME_SEARCH_START';
export const INCOME_SEARCH_SUCCESS = 'INCOME_SEARCH_SUCCESS';
export const INCOME_SEARCH_ERROR = 'INCOME_SEARCH_ERROR';

export const PAYMENT_SEARCH_START = 'PAYMENT_SEARCH_START';
export const PAYMENT_SEARCH_SUCCESS = 'PAYMENT_SEARCH_SUCCESS';
export const PAYMENT_SEARCH_ERROR = 'PAYMENT_SEARCH_ERROR';

export const MEMBER_SEARCH_START = 'MEMBER_SEARCH_START';
export const MEMBER_SEARCH_SUCCESS = 'MEMBER_SEARCH_SUCCESS';
export const MEMBER_SEARCH_ERROR = 'MEMBER_SEARCH_ERROR';

/* status */

export const GET_STATUS = 'GET_STATUS';
export const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS';
export const GET_STATUS_ERROR = 'GET_STATUS_ERROR';

/* employment */

export const GET_EMPLOYMENTS_LIST = 'GET_EMPLOYMENTS_LIST';
export const GET_EMPLOYMENTS_LIST_SUCCESS = 'GET_EMPLOYMENTS_LIST_SUCCESS';
export const GET_EMPLOYMENTS_LIST_ERROR = 'GET_EMPLOYMENTS_LIST_ERROR';

/* income */

export const GET_INCOME_LIST = 'GET_INCOME_LIST';
export const GET_INCOME_LIST_SUCCESS = 'GET_INCOME_LIST_SUCCESS';
export const GET_INCOME_LIST_ERROR = 'GET_INCOME_LIST_ERROR';

/* payment */

export const GET_PAYMENT_LIST = 'GET_PAYMENT_LIST';
export const GET_PAYMENT_LIST_SUCCESS = 'GET_PAYMENT_LIST_SUCCESS';
export const GET_PAYMENT_LIST_ERROR = 'GET_PAYMENT_LIST_ERROR';

export const GET_PAYMENT_ONE = 'GET_PAYMENT_ONE';
export const GET_PAYMENT_ONE_SUCCESS = 'GET_PAYMENT_ONE_SUCCESS';
export const GET_PAYMENT_ONE_ERROR = 'GET_PAYMENT_ONE_ERROR';

/* member */

export const GET_MEMBER_LIST = 'GET_MEMBER_LIST';
export const GET_MEMBER_LIST_SUCCESS = 'GET_MEMBER_LIST_SUCCESS';
export const GET_MEMBER_LIST_ERROR = 'GET_MEMBER_LIST_ERROR';

