import Axios from 'axios';
import { URL_PRESENT } from './URL';
import { headerConfig } from './cookie/common';

//채권 데이터 전체를 가져오는 api
export const getBonds = async (offset, pageLimit, currentPage, pageChunk) => {
    const response = await Axios.get(
        `${URL_PRESENT}/payment/bond/?offset=${offset}&limit=${pageLimit}&ordering=-created_time`, 
        headerConfig()
    );
    let data = [];
    data.push(response.data, currentPage, pageChunk);
    return data;
};

//특정 채권 데이터를 가져오는 api
export const getBondOne = async (uuid) => {
    const response = await Axios.get(
        `${URL_PRESENT}/payment/bond/${uuid}`, 
        headerConfig()
    );
    return response.data;
};

//특정 채권 상태를 변경하는 api
export const postModifyBondStatus = async (bondUuid, bondStatus) => {
    const response = await Axios.post(
        `${URL_PRESENT}/payment/bond/${bondUuid}/status/`, 
        bondStatus, 
        headerConfig()
    );
    return response;
};

//채권을 생성하는 api. (현재 쓰이지 않음)
export const bondPost = async (data) => {
    return Axios.post(
        `${URL_PRESENT}/payment/bond/`, 
        JSON.stringify(data), 
        headerConfig()
    )
    .then((response) => {
        alert('채권이 정상적으로 발행되었습니다.');
        window.location.reload();
        return response.data;
    })
    .catch((error) => {
        const status = error.response.status;
        const data = error.response.data;
        if(status === 400){
            if(data.message.payer_uuid__id){
                return alert(data.message.payer_uuid__id);
            } else if (data.message.requester_uuid__id){
                return alert(data.message.requester_uuid__id);
            } else if (data.message.name) {
                return alert(data.message.name)
            } else {
                return alert(data.message);
            }
        } else if (status === 500){
            return alert('created error, Please check again.')
        }
    })
};