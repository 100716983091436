import Axios from 'axios';
import { URL_PRESENT } from './URL';
import { headerConfig } from './cookie/common';

//회원 정보를 검색해오는 api (현재 안 쓰임)
export const getSearch = async (keyword) => {
    const response = await Axios.get(
        `${URL_PRESENT}/member/party/?username=${keyword}`, 
        headerConfig()
    );
    return response.data.results;
};

//채권 정보 검색 시 사용하는 filter api
export const bondSearch = async (query, offset, limit, currentPage, pageChunk) => {
    const response =  await Axios.get(
        `${URL_PRESENT}/payment/bond/?${query}&ordering=-created_time&offset=${offset}&limit=${limit}`, 
        headerConfig()
    );
    let data = [];
    data.push(response.data.count, response.data.results, query, currentPage, pageChunk);
    return data;
}

//취업 정보 검색 시 사용하는 filter api
export const employmentSearch = async (query, offset, limit, currentPage, pageChunk) => {
    const response = await Axios.get(
        `${URL_PRESENT}/employment/?${query}&ordering=-created_time&offset=${offset}&limit=${limit}`, 
        headerConfig()
    );
    let data = [];
    data.push(response.data.count, response.data.results, query, currentPage, pageChunk);
    return data;
};

//급여 정보 검색 시 사용하는 filter api
export const incomeSearch = async (query, offset, limit, currentPage, pageChunk) => {
    const response = await Axios.get(
        `${URL_PRESENT}/employment/income/?${query}&ordering=-created_time&offset=${offset}&limit=${limit}`, 
        headerConfig()
    );
    let data = [];
    data.push(response.data, query, currentPage, pageChunk);
    return data;
};

//청구서 정보 검색 시 사용하는 filter api
export const paymentSearch = async (query, offset, limit, currentPage, pageChunk) => {
    const response = await Axios.get(
        `${URL_PRESENT}/payment/scheduled-payment/?${query}&ordering=-created_time&offset=${offset}&limit=${limit}`, 
        headerConfig()
    );
    let data = [];
    data.push(response.data.count, response.data.results, query, currentPage, pageChunk);
    return data;
};

//회원 정보 검색 시 사용하는 filter api
export const memberSearch = async (query, offset, limit, currentPage, pageChunk) => {
    const response = await Axios.get(
        `${URL_PRESENT}/member//?${query}&ordering=-party__date_joined&offset=${offset}&limit=${limit}`, 
        headerConfig()
    );
    let data = [];
    data.push(response.data.count, response.data.results, query, currentPage, pageChunk);
    return data;
};
