import React from 'react';
import { withRouter } from 'react-router';

const NotFound404 = (props) => {

    return(
        <div className="not-found-404-wrap">
            <h1>404 NOT FOUND</h1> 
            <h2>Page Is Lost</h2>
        </div>
    );
};

export default withRouter(NotFound404);