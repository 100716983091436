import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Table, Badge } from 'reactstrap';
import { commonCost, autoHyphenDate } from '../common/common';
import LoadingContent from '../common/LoadingContent';
import ModifyScpStatus from './typeParts/ModifyScpStatus';
import { scheduledPaymentStatus, scheduledPaymentType, copyScheduledPaymentUuid } from './typeParts/typeFunc';

const TypePaymentList = (props) => {

    const { loading, data } = useSelector(state => state.status.statuses);
    let contents;

    if(loading === 'loading...' && !data) {
        contents = <tr>
            <td colSpan="6">
                <LoadingContent />
            </td>
        </tr>
    } else if (data && data.length >= 1) {
        contents = data.map(data => {
            let payment_status = data?.scheduled_payment_status;
            return(
                <tr align="center" key={data.uuid}>
                    <td>{data.payment_turn}</td>
                    <td>{scheduledPaymentType(data.scheduled_payment_type)}</td>
                    <td>{data?.payment_due_date}</td>
                    <td>{
                        (payment_status && [3,5].includes(payment_status?.scheduled_payment_status)) ? 
                        autoHyphenDate(payment_status?.valid_from) : 
                        '' 
                    }</td>
                    <td>{commonCost(data.payment_amount)}</td>
                    {(props.mode === 'read') ?
                        <td>{scheduledPaymentStatus(payment_status?.scheduled_payment_status, Badge)}</td> :
                        <td>{<ModifyScpStatus scpUuid={data.uuid} scpStatus={payment_status?.scheduled_payment_status} paymentTurn={data.payment_turn} bondUuid={props.statusData.uuid} />}</td>   
                    }
                    <td>{copyScheduledPaymentUuid(data.uuid)}</td>
                </tr>
            );
        });
    } else {
        contents = <tr>
            <td colSpan="6" style={{textAlign: "center"}}>청구서 정보가 없습니다.</td>
        </tr>
    };

    return( 
        <div className="statustable-wrapper">
            <Table className="status-table">
                <thead>
                    <tr align="center">
                        <td>회차</td> 
                        <td>납부유형</td>
                        <td>납부기한</td>
                        <td>납부일</td>
                        <td>납부금액</td> 
                        <td>상태</td>
                        <td>UUID 복사</td>
                    </tr> 
                </thead>
                <tbody>
                    {contents}
                </tbody>
            </Table>
        </div>
    );
};

export default withRouter(TypePaymentList);