import React, { Fragment, useState, useEffect } from 'react';
import { getUserInfo } from '../../api/employmentAPI';
import { getBondOne } from '../../api/bondAPI';
import { commonCost, autoHyphenPhone, autoHyphenDate, genderTrans, addressTrans, userCourse } from '../common/common';
import TypeComponent from './typeParts/TypeComponent';
import { depositPolicyType } from './typeParts/typeFunc';

const TypePrepaid = (props) => {

    const [userInfo, setUserInfo] = useState('');
    const [userBond, setUserBond] = useState('');

    const loadData = async () => {
        const data = await getUserInfo(props.data.id); 
        setUserInfo(data);
    };
        
    const loadDataTwo = async () => {
        const data = await getBondOne(props.data.uuid);  
        setUserBond(data);
    }
    
    useEffect(() => { loadDataTwo() }, []);
    useEffect(() => { loadData() }, []);

    if(userBond && userInfo) {
        let { bond_end_date, total_payment_amount, deposit_policy } = userBond.bond_policy;
        return(
            <Fragment>
                <div className="general-form profile-info">
                    <div className="photo-form profile"></div>
                    <div className="info-form">
                        <h5>납부자 정보</h5>
                        <TypeComponent name="이름" function={userInfo.party.username} />
                        <TypeComponent name="성별" function={genderTrans(userInfo.gender)} />
                        <TypeComponent name="생년월일" function={userInfo.birthday} />
                        <TypeComponent name="이메일" function={userInfo.party.email} />
                        <TypeComponent name="연락처" function={autoHyphenPhone(userInfo.party.phone)} />
                        <TypeComponent name="주소" function={addressTrans(userInfo.address)} />
                    </div>
                </div>
    
                <div className="general-form profile-info">
                    <div className="info-form profile2">
                        <h5>채권정보</h5>
                        <TypeComponent name="채권 유형" function="일반(선불제)" />
                        <TypeComponent name="과정 명" function={userCourse(userBond.name)} />
                        <TypeComponent name="납부 기한" function={autoHyphenDate(bond_end_date)} />
                        <TypeComponent name="횟수(회)" function="1" />
                        <TypeComponent name="총 납부 금액(원)" function={commonCost(total_payment_amount)} />
                        <TypeComponent name="보증금 정책" function={depositPolicyType(deposit_policy)} />
                        {(deposit_policy) ? 
                            <TypeComponent name="보증금(원)" function={commonCost(deposit_policy?.deposit)} /> :
                            <TypeComponent name="보증금(원)" function={null} />
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
    
    return(
        <>
        </>
    )
};

export default TypePrepaid;