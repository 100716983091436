import React, { useState } from 'react';
import { useSelector } from 'react-redux';

//최상단에서 현재 접속 중인 회원 이름과 접속일 출력되도록 하는 component
const UserInfo = () => {
    const userInfo = useSelector((state) => state.users.data.data);
    const [user, setUser] = useState('');
    const [userCss, setUserCss] = useState('');
    let contents = '';
    let current = new Date().toISOString();
    current = current.split('T')[0];

    const openInfo = () => {
        setUser(userInfo.email);
        setUserCss(!userCss);
    }

    if(user) {
        contents = <div className={`userInfo ${userCss ? "active" : "noneActive"}`}>
            {user}
            <br />
            접속일 : {current}
        </div>
    }

    if(userInfo) {
        return(
            <>
            <span style={{cursor: 'pointer'}} onClick={openInfo}>{userInfo.username} 님</span>
            {contents}
            </>
        )
    }

    return (
        <>
        </>
    )

};

export default UserInfo;