import Axios from 'axios';
import { URL_PRESENT } from './URL';
import { headerConfig } from './cookie/common';

//특정 회원의 정보를 가져오는 api
export const getUserInfo = async (id) => {
    const response = await Axios.get(
        `${URL_PRESENT}/member/${id}/`, 
        headerConfig()
    );
    return response.data;
};

//취업 데이터를 가져오는 api
export const getEmploymentPart = async (offset, pageLimit, currentPage, pageChunk) => {
    const response = await Axios.get(
        `${URL_PRESENT}/employment/?offset=${offset}&limit=${pageLimit}&ordering=-created_time`, 
        headerConfig()
    );
    let data = [];
    data.push(response.data, currentPage, pageChunk);
    return data;
};