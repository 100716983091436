import React from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../parts/Header';
import Sidebar from '../parts/Sidebar';
import Footer from '../parts/Footer';

const Board = () => {
    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            <div className="board-content">
                <div className="main-content">
                    <div className="board-main">
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default withRouter(Board);