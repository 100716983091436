import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Table, Badge } from 'reactstrap';
import UpdateForm from './form/UpdateForm';

const UpdateTable = ({data}) => {

    const [ isClicked, setIsClicked ] = useState('False');

    function handlePageMove(uuid) {
        setIsClicked({
            isClicked: 'True'
        })

        handleView(uuid);
    }

    function handleView(uuid) {
        return <UpdateForm uuid={uuid} />
    }


    if(isClicked === 'False'){
        return (
            <Fragment>
                <Table hover size="sm" className="user-table">
                    <thead>
                        <tr align="center">
                            <td width="100">이름</td> 
                            <td width="100">전화번호</td>
                            <td width="100">채권상태</td> 
                            <td width="150">채권명</td> 
                            <td width="80">납부시작일</td>
                            <td width="80">납부종료일</td>
                        </tr> 
                    </thead>
                    <tbody>
                        {data.map(data => <tr
                            onClick={() => { handlePageMove(data.uuid) }}
                            align="center" 
                            key={data.uuid}
                            > 
                            <td>{data.payer_uuid.username}</td> 
                            <td>{data.payer_uuid.phone}</td>
                            <td>
                            { (() => { 
                                    const bond_status = data.bond_status;
                                    if(bond_status !== null){
                                    const status = data.bond_status.bond_status;
                                    if(status === 1 ) {
                                        return <h6><Badge color="secondary" pill>납부 전</Badge></h6>
                                    } else if(status === 2 ) {
                                        return <h6><Badge color="primary" pill>납부 진행</Badge></h6>
                                    } else if(status === 3 ) {
                                        return <h6><Badge color="success" pill>납부 완료</Badge></h6>
                                    } else if(status === 4 ) {
                                        return <h6><Badge color="warning" pill>납부 연체</Badge></h6>
                                    } else if(status === 5 ) {
                                        return <h6><Badge color="warning" pill>납부 완료(연체)</Badge></h6>
                                    } else if(status === 6 ) {
                                        return <h6><Badge color="danger" pill>납부 연체(추심)</Badge></h6>
                                    } else if(status === 7 ) {
                                        return <h6><Badge color="danger" pill>강제 종료</Badge></h6>
                                    } else if(status === 8 ) {
                                        return <h6><Badge color="dark" pill>무효</Badge></h6>
                                    } else if(status === 9 ) {
                                        return <h6><Badge color="dark" pill>포기</Badge></h6>
                                    } else {
                                        return '알 수 없음'
                                    }
                                    } else {
                                    return '알 수 없음'
                                    }
                                })() }
                            </td> 
                            <td>{data.name}</td> 
                            <td>{data.bond_policy.payment_start_date}</td> 
                            <td>{data.bond_policy.payment_end_date}</td>
                        </tr> )}
                    </tbody>
                </Table>
            </Fragment>
        )
    }

    return (
        <div>
            d
        </div>
    )

}



export default withRouter(UpdateTable);