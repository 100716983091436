import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import { compareLatest, userCourse, commonCost, autoHyphenPhone, autoHyphenDate } from '../common/common';
import EmptyPage from '../common/EmptyPage';
import { userEmploy, leaveReason, TableExport, TableThread, validFromTo } from './employParts/employFunc';
import ImageViewerModal from '../common/ImageViewerModal';

const EmploymentTable = (props) => {
    const [employmentData, setUserData] = useState('');
    let searchCount = useSelector(state => state.searches.employSearches.count);
    
    useEffect(() => {
        let tr = document.getElementsByTagName('tr');
        let tdArr = [];
        for(let i = 1; i < tr.length; i++) {
            tdArr.push({
                "createdTime" : tr[i].cells[1].innerHTML,
                "name" : tr[i].cells[2].innerHTML,
                "phone" : tr[i].cells[3].innerHTML,
                "course" : tr[i].cells[4].innerHTML,
                "employmentStatus" : tr[i].cells[5].innerHTML,
                "companyName" : tr[i].cells[6].innerHTML,
                "incomeDate" : tr[i].cells[7].innerHTML,
                "annualIncome" : tr[i].cells[8].innerHTML,
                "hireDate" : tr[i].cells[9].innerHTML,
                "resignDate" : tr[i].cells[10].innerHTML,
                "leaveReason" : tr[i].cells[11].innerHTML,
            })
        };
        setUserData(tdArr);
    }, []);

    let search_result = null;
    if(searchCount){
        search_result = <p style={{textAlign: 'center', marginTop: '50px'}}>{searchCount} 개의 검색 결과가 있습니다.</p>;
    }
    
    if(props.data && props.data.length > 0){
        return (
            <div className="table-wrapper">
                <TableExport data={employmentData} />
                <Table hover size="sm">
                    <TableThread />
                    <tbody>
                        {props.data.map((data) => {
                            return (
                                <tr align="center" key={data.uuid}>
                                    <td>{compareLatest(data.created_time)}</td>
                                    <td>{autoHyphenDate(data.created_time)}</td>
                                    <td>{data.party.username}</td> 
                                    <td>{autoHyphenPhone(data.party.phone)}</td>
                                    <td>{userCourse(data.bond_name)}</td>
                                    <td>{userEmploy(data.employment_status)}</td>
                                    <td>{data.company_name}</td>
                                    <td>{data.payday}</td>
                                    <td>{commonCost(data.annual_income)}</td>
                                    <td>{validFromTo(data.employment_status, 'validFrom')}</td>
                                    <td>{validFromTo(data.employment_status, 'validTo')}</td>
                                    <td>{leaveReason(data.employment_status)}</td>
                                    <td><ImageViewerModal uuid={data.uuid} page="employment" /></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                {search_result}
            </div>
        )
    } else if (props.data && props.data.length <= 0) {
        return (
            <EmptyPage thread={<TableThread />} colspan={13} />
        )
    }
    return (
        <>
        </>
    )
};

export default EmploymentTable;
