import React, { Fragment, useEffect, useState } from 'react';
import { getBondOne } from '../../api/bondAPI';
import { getUserInfo } from '../../api/employmentAPI';
import { commonCost, autoHyphenPhone, autoHyphenDate, userCourse, genderTrans, addressTrans } from '../common/common';
import { depositPolicyType, incomeShareMonth, interestPolicy, interestRate } from './typeParts/typeFunc';
import TypeComponent, { TypeInterestsComponent } from './typeParts/TypeComponent';

const TypeISA = (props) => {

    const [userBond, setUserBond] = useState('');
    const [userInfo, setUserInfo] = useState('');
    
    const loadData = async () => {
        const data = await getUserInfo(props.data.id);        
        setUserInfo(data);
    }
    
    const loadDataTwo = async () => {
        const data = await getBondOne(props.data.uuid);  
        setUserBond(data);
    }

    useEffect(() => { loadData() }, []);
    useEffect(() => { loadDataTwo() }, []);
    
    if(userBond && userInfo) {
        let { income_share_percentage, payment_cap, total_payment_times, minimum_income_threshold } = userBond.bond_policy.isa_policy;
        let { interest_policy, deposit_policy, bond_start_date, bond_end_date } = userBond.bond_policy;

        return (
            <Fragment>
                <div className="general-form profile-info">
                    <div className="photo-form profile"></div>
                    <div className="info-form">
                        <h5>고객 정보</h5>
                        <TypeComponent name="이름" function={userInfo.party.username} />
                        <TypeComponent name="성별" function={genderTrans(userInfo.gender)} />
                        <TypeComponent name="생년월일" function={userInfo.birthday} />
                        <TypeComponent name="이메일" function={userInfo.party.email} />
                        <TypeComponent name="연락처" function={autoHyphenPhone(userInfo.party.phone)} />
                        <TypeComponent name="주소" function={addressTrans(userInfo.address)} />
                    </div>
                </div>
    
                <div className="general-form profile-info">
                    <div className="info-form profile2">
                        <h5>채권정보</h5>
                        <TypeComponent name="채권 유형" function="소득공유 후불제(ISA)" />
                        <TypeComponent name="과정 명" function={userCourse(userBond.name)} />
                        <TypeComponent name="채권 시작일" function={autoHyphenDate(bond_start_date)} />
                        <TypeComponent name="채권 종료일" function={autoHyphenDate(bond_end_date)} />
                        <TypeComponent name="소득공유 약정 기간(개월)" function={incomeShareMonth(autoHyphenDate(bond_start_date), autoHyphenDate(bond_end_date))} />
                        <TypeComponent name="소득공유 비율(%)" function={Math.floor(income_share_percentage * 100)} />
                        <TypeComponent name="횟수(회)" function={total_payment_times} />
                        <TypeComponent name="납부 최소 소득(원)" function={commonCost(minimum_income_threshold)} />
                        <TypeComponent name="최대 납부 금액(원)" function={commonCost(payment_cap)} />
                        <TypeComponent name="보증금 정책" function={depositPolicyType(deposit_policy)} />
                        {(deposit_policy) ? 
                            <TypeComponent name="보증금(원)" function={commonCost(deposit_policy?.deposit)} /> :
                            <TypeComponent name="보증금(원)" function={null} />
                        }
                        <TypeComponent name="이자율 정책" function={interestPolicy(interest_policy)} />
                        <TypeInterestsComponent function={interestRate(interest_policy)} />
                    </div>
                </div>
            </Fragment> 
        )
    }   
    return (
        <></>
    )
};

export default TypeISA;