import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import { TableThread, TableExport } from './memberParts/memberFunc';
import { compareLatest, autoHyphenPhone, autoHyphenDate, genderTrans } from '../common/common';
import EmptyPage from '../common/EmptyPage';
import { useSelector } from 'react-redux';

const MemberTable = (props) => {

    const [memberData, setMemberData] = useState('');
    let searchResult = null; 
    let searchCount = useSelector(state => state.searches.memberSearches.count);

    useEffect(() => {
        let tr = document.getElementsByTagName('tr');
        let tdArr = [];

        for(let i = 1; i < tr.length; i++) {
            tdArr.push({
                "name" : tr[i].cells[2].innerHTML,
                "phone" : tr[i].cells[3].innerHTML,
                "email" : tr[i].cells[4].innerHTML,
                "gender" : tr[i].cells[5].innerHTML,
                "birthDay" : tr[i].cells[6].innerHTML,
                "address" : tr[i].cells[7].innerHTML,
            })
        };

        setMemberData(tdArr);

    }, []);

    if(searchCount){
        searchResult = <p style={{textAlign: 'center', marginTop: '50px'}}>{searchCount} 개의 검색 결과가 있습니다.</p>;
    };

    if(props.data && props.data.length > 0){
        return (
            <div className="table-wrapper">
                <TableExport data={memberData} />
                <Table hover size="sm">
                    <TableThread />
                    <tbody>
                        {props.data.map((member) => {
                            return (
                                <tr align="center" key={member.party.id}>
                                    <td>{compareLatest(member.party.dateJoined)}</td> 
                                    <td>{autoHyphenDate(member.party.dateJoined)}</td>
                                    <td>{member.party.username}</td>
                                    <td>{autoHyphenPhone(member.party.phone)}</td> 
                                    <td>{member.party.email}</td>
                                    <td>{genderTrans(member.gender)}</td>
                                    <td>{member.birthday}</td>
                                    <td>{member.address}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                {searchResult}
            </div>
        );
    } else if (props.data && props.data.length <= 0){
        return (
            <EmptyPage thread={<TableThread />} colspan={8} />
        )
    };

    return (
        <>
        </>
    );
};

export default MemberTable;