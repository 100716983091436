import React from 'react';
import { Table } from 'reactstrap';

//검색 결과 시 데이터가 없을 경우 보여주는 component
const EmptyPage = (props) => {

    return(
        <div className="table-wrapper">
            <Table hover size="sm">
                {props.thread}
                <tbody>
                    <tr>
                        <td colSpan={props.colspan} style={{textAlign:'center'}}>검색 결과가 없습니다.</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );

};

export default EmptyPage;
