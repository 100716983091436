import { currMonth } from '../../common/common'; 

//청구서 타입 출력 함수
export const scheduledPaymentType = (type) => {
    switch (type) {
        case 1:
            return '일반 납부';
        case 2:
            return '선납';
        case 3:
            return '최대 금액 납부';
        case 4:
            return '보증금';
        case 5:
            return '서비스 이용료';
        case 6:
            return '기타';
        default:
            return '';
    };
};

//청구서 상태 출력 함수
export const scheduledPaymentStatus = (status, Badge) => { 
    switch (status) {
        case 1:
            return <Badge color="secondary" pill>납부 전</Badge>;
        case 2:
            return <Badge color="primary" pill>납부 진행</Badge>;
        case 3:
            return <Badge color="success" pill>납부 완료</Badge>;
        case 4:
            return <Badge color="warning" pill>납부 연체</Badge>;
        case 5:
            return <Badge color="warning" pill>납부 완료(연체)</Badge>;
        case 6:
            return <Badge color="danger" pill>납부 연체(추심)</Badge>;
        case 7:
            return <Badge color="dark" pill>강제 종료</Badge>;
        case 8:
            return <Badge color="dark" pill>무효</Badge>;
        case 9:
            return <Badge color="dark" pill>포기</Badge>;
        case 10:
            return '알 수 없음';
        case 11:
            return <Badge color="dark" pill>미납</Badge>;
        default:
            return '';
    };
};

//소득공유 약정 기간 계산 함수
export const incomeShareMonth = (startDate, endDate) => {
    let diff = new Date(endDate) - new Date(startDate);
    return Math.floor(diff/currMonth);
};

//청구서 횟수 계산 함수
export const paymentListCount = (data) => {
    if(data) {
        //'서비스이용료' 유형이 들어간 원소 객체 filter
        const result = data.filter(function (element) { return element.scheduled_payment_type !== 5 });
        return result.length;
    };
    return '';
};

//보증금 타입 출력 함수
export const depositPolicyType = (depositType) => {
    switch (depositType && depositType?.type) {
        case 'INCLUDE_1ST_PAYMENT':
            return 'ISA 보증금(1회차 납부로 대신함)';
        case 'GENERAL':
            return '일반 보증금(조건 만족시 환급)';
        default:
            return '없음';
    };
};

//이율 출력 함수
export const interestPolicy = (interestPolicy) => {
    switch (interestPolicy && interestPolicy?.apply_type) {
        case 'SEPARATION':     
            return '분리 이율';
        case 'VARIANCE':
            return '변동 이율';
        case 'ACCUMULATE':
            return '누적 이율';
        default:
            return '없음';
    };
};

//이자율 출력 함수
export const interestRate = (interest_policy) => {
    if(interest_policy) {
        let { interests } = interest_policy;
        let interestsRateArr = [];
        for(let i = 0; i < interests?.length; i++){
            interestsRateArr.push({ rate : interests[i]?.rate });
        };
        return interestsRateArr;
    };
    return '';
};

//청구서 uuid 복사 함수
export const copyScheduledPaymentUuid = (uuid) => {

    const handleCopyUuid = (e) => {
        e.preventDefault();
        let targetText = document.getElementById(`${e.target.value}`);
        targetText.select();
        let success = document.execCommand('copy');
        targetText.setSelectionRange(0, 0);
        if(success) {
            alert('복사되었습니다.');
        };
    };

    return(
        <>
            <input type="text" value={uuid} id={uuid} className="uuid-input" readOnly />
            <button onClick={handleCopyUuid} value={uuid} className="uuid-copy-button" >UUID 복사</button>
        </>
    );
};