import React, { useState } from 'react';
import { AiFillCheckCircle } from "react-icons/ai";
import { postModifyBondStatus } from '../../../api/bondAPI';

const ModifyBondStatus = (props) => {

    const [bondStatusNum, setBondStatusNum] = useState(props.bondStatus);
    const [currBondStatusNum, setCurrBondStatusNum] = useState(props.bondStatus);

    //select의 option의 value 값과 string 값을 object화 시킨다.
    const bondStatusArr = {
        1: "납부 전", 
        2: "납부 진행", 
        3: "납부 완료", 
        4: "납부 연체", 
        5: "납부 완료(연체)", 
        6: "납부 연체(추심)", 
        7: "강제 종료", 
        8: "무효", 
        9: "포기", 
        11: "미납",
    };

    const handleOnchange = (e) => {
        e.preventDefault();
        const {value} = e.target;
        setBondStatusNum(parseInt(value));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const postData = { "bond_status" : bondStatusNum };
        let checkConfirm = window.confirm(`[${props.bondName}] 채권 상태를 ${bondStatusArr[bondStatusNum]}(으)로 수정하시겠습니까?`);
        
        if (checkConfirm) {
            if (postData.bond_status !== 0) {
                return await postModifyBondStatus(props.bondUuid, postData)
                    .then((response) => {
                        alert(`[${props.bondName}] 채권 상태가 성공적으로 변경되었습니다.`);
                        setCurrBondStatusNum(parseInt(bondStatusNum));
                    })
                    .catch((err) => {
                        //api 호출 이후 reject 된 상태들
                        alert(`[${props.bondName}] 채권 상태 변경 error \n 
                            http code : ${err.response?.status} message : ${err.response?.data?.message}`);
                    });
            };
        };
    };

    //서버에 저장되어 있는 상태값을 disabled하고 나머지 상태값은 정상적으로 노출되도록 렌더링
    let options = [];
    for (let key in bondStatusArr) {
        options.push((parseInt(key) === parseInt(currBondStatusNum)) ? 
        <option value={key} key={key} disabled>{bondStatusArr[key]}</option> :
        <option value={key} key={key} >{bondStatusArr[key]}</option> )
    };

    return (
        <>
            {/* props.bondStatus와 사용자가 지정한 상태값이 같고 다름에 따라 버튼 활성화 구현 */}
            {(currBondStatusNum === bondStatusNum) ?
                <AiFillCheckCircle className="bond-modify-status-disabled right "  /> :
                <AiFillCheckCircle className="bond-modify-status right" onClick={handleSubmit} />
            }
            <select 
                className="modify-status-select"
                value={bondStatusNum}
                onChange={handleOnchange}
            >   
                {options}
            </select>
        </>
    );
};

export default ModifyBondStatus;