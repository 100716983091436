import dotenv from 'dotenv';

dotenv.config();

export let URL_PRESENT;

if(process.env.REACT_APP_HDM_API_ENV === 'production'){
    //run build 했을 시 적용되는 환경 : production
    URL_PRESENT = 'https://v4.api.hakdokman.com/api/v4';
} else {
    //npm start 시 적용되는 환경 : development
    URL_PRESENT = 'https://v4.api.dev.hakdokman.com/api/v4';
}

