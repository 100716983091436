import { 
    GET_STATUS,
    GET_STATUS_SUCCESS,
    GET_STATUS_ERROR
} from '../_actions/_types';

//초깃값
const initialState = {
    statuses : {
        loading : null,
        data: null,
        error: null,
    }
}

//상태 리듀서
export default function statusReducer(state = initialState, action) {
    switch (action.type) {
        case GET_STATUS:
            return {
                ...state,
                statuses: {
                    loading: 'loading...',
                    data: null,
                    error: null,
                }
            };
        case GET_STATUS_SUCCESS:
            return {
                ...state,
                statuses: {
                    loading: 'loading success...',
                    data: action.data,
                    error: null,
                }
            };
        case GET_STATUS_ERROR:
            return {
                ...state,
                statuses: {
                    loading: 'loading error...',
                    data: null,
                    error: action.error,
                }
            };
        default:
            return state;
    }
};

