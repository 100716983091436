import Axios from 'axios';
import { URL_PRESENT } from './URL';
import { headerConfig } from './cookie/common';


//급여 데이터를 가져오는 api
export const getIncomePart = async (offset, pageLimit, currentPage, pageChunk) => {
    const response = await Axios.get(
        `${URL_PRESENT}/employment/income/?offset=${offset}&limit=${pageLimit}&ordering=-created_time`, 
        headerConfig()
    );

    let data = [];
    data.push(response.data, currentPage, pageChunk);
    
    return data;
};

//급여 데이터를 삭제하는 api
export const deleteIncome = async (employmentUuid, incomeUuid, deleteQuery) => {
    const response = await Axios.delete(
        `${URL_PRESENT}/employment/${employmentUuid}/income/${incomeUuid}?${deleteQuery}`,
        headerConfig()
    );
    return response;
};
